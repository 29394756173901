import { render, staticRenderFns } from "./AddCommentModal.vue?vue&type=template&id=229b8706&scoped=true&lang=pug&"
import script from "./AddCommentModal.vue?vue&type=script&lang=ts&"
export * from "./AddCommentModal.vue?vue&type=script&lang=ts&"
import style0 from "./AddCommentModal.vue?vue&type=style&index=0&id=229b8706&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "229b8706",
  null
  
)

export default component.exports