

import {Component, Prop} from "vue-property-decorator";
import Nl2br from "../../../common/element/Nl2br.vue";
import MySiteVue from "../../components/MySiteVue";
import {ProductEntity} from "../../types/entity";
import * as _ from 'lodash';
import {ProductStockAvailabilityType} from "@/common/constants/ProductStockAvailabilityType";

@Component({
    components: {
        Nl2br
    }
})
export default class ProductEntitySchema extends MySiteVue {
    @Prop({required: true, type: Object})
    readonly product!: ProductEntity;

    get
    bigImages() {
        return _.map(this.product.images, 'url');
    }

    get
    productStockAvailabilityMetaType() {
        const type = this.product.stockAvailability;
        if (type === ProductStockAvailabilityType.Order) {
            return 'http://schema.org/PreOrder';
        }
        if (type === ProductStockAvailabilityType.Yes) {
            return 'http://schema.org/InStock';
        }
        if (type === ProductStockAvailabilityType.No) {
            return 'http://schema.org/SoldOut';
        }
        return '';
    }

    get
    url() {
        return document.location.href;
    }
}
