
import {Component} from "vue-property-decorator";
import AddCommentModal from "../components/modal/AddCommentModal.vue";
import CommentItem from "./product/CommentItem.vue";
import MySiteVue from "../components/MySiteVue";
import {OrderEntity} from "../types/entity";
import SiteApiService from "@/main/service/SiteApiService";
import Utils from "@/common/service/Utils";
import * as _ from 'lodash';
import PageTitle from "@/main/components/common/PageTitle.vue";
import OrderForm from "@/main/components/order/OrderForm.vue";
import { MutationType } from "@/main/constants/MutationType";

@Component({
    components: {
        PageTitle,
        OrderForm
    }
})
export default class UserOrdersListPage extends MySiteVue {

    private orders: OrderEntity[] = [];
    private openedAccordions: number[] = [];
    private isLoading: boolean = false;
    private isLoaded: boolean = false;

    mounted() {
        if (!this.isLoggedIn) {
            this.$router.replace('/');
        }
    }

    async created() {
        this.isLoading = true;
        const response = await SiteApiService.orderList();
        if (response.isSuccess) {
            this.orders = response.data.items;
            this.isLoading = false;
            this.isLoaded = true;
        }
        this.isLoading = false;
        this.isLoaded = true;

        this.$store.commit(
            MutationType.BROWSER_META_SET,
            {
                seoTitle: 'Мои заказы',
                seoDescription: '',
                seoKeywords: ''
            }
        );
    }

    getOrderItemTitle(order: OrderEntity) {
        return `${order.basket.productsQuantity} товаров`;
    }

    getOrderCommonAmount(order: OrderEntity) {
        return `Итог: <b>${Utils.toMoneyFormat(order.basket.amount)}</b>`;
    }

    isItemOpened(orderNumber: number) {
        return _.find(this.openedAccordions, item => item === orderNumber);
    }
}
