

import {Component, Prop} from "vue-property-decorator";
import MySiteVue from "@/main/components/MySiteVue";

@Component({
    components: {}
})
export default class PageTitle extends MySiteVue {
    @Prop({required: true, type: String})
    readonly title!: string;
}
