
import {Prop, Watch} from "vue-property-decorator";
import {BrandEntity, FeatureEntity, FeatureOptionEntity, ProductCommentEntity} from "@/main/types/entity";
import Component from "vue-class-component";
import CustomModal from "@/common/modal/CustomModal.vue";
import NumberInputField from "@/common/element/form/NumberInputField.vue";
import Spinner from "@/common/element/Spinner.vue";
import Multiselect from "vue-multiselect";
import {MutationType} from "@/main/constants/MutationType";
import {Action, Mutation, State} from "vuex-class";
import {FilterLoadDataMutationModel} from "@/main/types/mutationModel";
import {ActionType} from "@/main/constants/ActionType";
import {FilterSelectedState} from "@/main/types/store";
import * as _ from 'lodash';
import {MultiSelectOption} from "@/common/type/common";
import MySiteVue from "@/main/components/MySiteVue";

@Component({
    components: {
        CustomModal,
        NumberInputField,
        Spinner,
        Multiselect
    }
})
export default class FilterForm extends MySiteVue {

    @Prop({required: false, type: String, default: ''})
    readonly categoryShortName!: string;

    @Prop({required: false, type: String, default: ''})
    readonly brandShortName!: string;

    @Prop({required: false, type: String, default: ''})
    readonly searchString!: string;

    @State(state => state.filter.brands)
    readonly brands!: BrandEntity[];

    @State(state => state.filter.features)
    readonly features!: FeatureEntity[];

    @State(state => state.filter.isLoading)
    readonly isLoading!: boolean;

    @Mutation(MutationType.FILTER_RESET)
    readonly filterReset!: () => void;

    @Mutation(MutationType.FILTER_CHANGE)
    readonly filterChange!: (data: FilterSelectedState) => void;

    @Action(ActionType.FILTER_LOAD_DATA)
    readonly filterLoadData!: (data: FilterLoadDataMutationModel) => void;

    @Action(ActionType.PRODUCTS_LIST_RELOAD)
    readonly productsListReload!: () => void;

    private checkedBrands: BrandEntity[] = [];
    private checkedOptions: FeatureOptionEntity[] = [];

    private minPriceFieldValue: number | null = null;
    private maxPriceFieldValue: number | null = null;

    created() {
        this.loadData();
    }

    loadData() {
        this.filterReset();
        this.filterLoadData({
            categoryShortName: this.categoryShortName,
            brandShortName: this.brandShortName,
            searchString: this.searchString
        });
    }

    onReset() {
        this.checkedBrands = [];
        this.checkedOptions = [];
        this.minPriceFieldValue = null;
        this.maxPriceFieldValue = null;
        this.$store.commit(MutationType.FILTER_PARTIAL_RESET);
        this.$store.dispatch(ActionType.PRODUCTS_LIST_RELOAD);
    }

    changeFilter(dataToChange: FilterSelectedState) {
        this.filterChange(dataToChange);
        this.productsListReload();
    }

    onChangeBrand(selected: MultiSelectOption) {
        let toFind = _.map(this.checkedBrands, item => item.id) as number[];
        toFind.push(selected.id as number);
        this.changeFilter({
            brandIds: toFind
        } as FilterSelectedState);
    }


    onRemoveBrand(removed: MultiSelectOption) {
        let toFind = _.filter(this.checkedBrands, item => item.id !== removed.id);
        this.changeFilter({
            brandIds: _.map(toFind, item => item.id)
        } as FilterSelectedState);
    }

    onChangeOptions(selected: MultiSelectOption) {
        let optionIds: number[] = [];
        this.checkedOptions.forEach((option: FeatureOptionEntity) => {
            optionIds = _.concat(optionIds, [option.id]);
        });
        optionIds.push(selected.id as number);
        this.changeFilter({
            optionIds: _.filter(optionIds, item => item)
        } as FilterSelectedState);
    }

    onRemoveOptions(removed: MultiSelectOption) {
        let optionIds: number[] = [];
        optionIds = _.concat(
            optionIds,
            _.map(
                _.filter(this.checkedOptions, item => item?.id !== removed.id),
                'id'
            )
        );
        this.changeFilter({
            optionIds: _.filter(optionIds, item => item)
        } as FilterSelectedState);
    }

    onChangePrice(fieldName: string, value: any) {
        let filterModel = {} as FilterSelectedState;
        _.set(filterModel, fieldName, value);
        this.changeFilter(filterModel);
    }

    @Watch('categoryShortName')
    categoryShortNameWatcher() {
        this.loadData();
    }

    @Watch('searchString')
    searchStringWatcher() {
        this.loadData();
    }

    @Watch('brandShortName')
    brandShortNameWatcher() {
        this.loadData();
    }
}
