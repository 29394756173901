

import QuantityInputField from "@/common/element/form/QuantityInputField.vue";
import Component from "vue-class-component";
import {BasketItemEntity} from "@/main/types/entity";
import {MutationType} from "@/main/constants/MutationType";
import {Action, Mutation, State} from "vuex-class";
import {ActionType} from "@/main/constants/ActionType";
import MySiteVue from "../MySiteVue";

@Component({
    components: {
        QuantityInputField
    }
})
export default class BasketProductsTable extends MySiteVue {

    @Mutation(MutationType.BASKET_CLOSE_MODAL)
    readonly closeModal!: () => void;

    @Action(ActionType.BASKET_ADD_REMOVE_PRODUCT)
    readonly changeProductQuantity!: (data: { productId: number, quantity: number }) => void;

    @State(state => state.basket.basketItems)
    readonly basketItems!: BasketItemEntity[];

    @State(state => state.basket.amount)
    readonly amount!: number;

    @State(state => state.basket.productsQuantity)
    readonly productsQuantity!: number;

    @State(state => state.basket.isLoading)
    readonly isBasketDataLoading!: boolean;

    getBasketItemImageUrl(basketItem: BasketItemEntity) {
        if (basketItem.product && basketItem.product.images.length > 0) {
            return basketItem.product.images[0].thumbnailUrl;
        }
        return '@/main/assets/images/product_default.png';
    }

    onChangeProductQuantity(productId: number, quantity: number) {
        this.changeProductQuantity({ productId, quantity });
    }

    get
    basketItemsCount() {
        return this.basketItems ? this.basketItems.length : 0;
    }
}
