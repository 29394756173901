

import Component from "vue-class-component";
import TopBrands from "@/main/components/common/footer/TopBrands.vue";
import Header from "@/main/components/common/header/Header.vue";
import Breadcrumbs from "@/main/components/common/Breadcrumbs.vue";
import BasketModal from "@/main/components/basket/BasketModal.vue";
import UserLoginModal from "@/main/components/modal/UserLoginModal.vue";
import UserRegistrationModal from "@/main/components/modal/UserRegistrationModal.vue";
import MySiteVue from "@/main/components/MySiteVue";
import {Getter} from "vuex-class";

@Component({
    components: {
        TopBrands,
        Header,
        Breadcrumbs,
        BasketModal,
        UserLoginModal,
        UserRegistrationModal
    }
})
export default class AppLayout extends MySiteVue {
    @Getter('isAppInitialized')
    readonly isAppInitialized!: boolean;
}
