
import {Component, Prop} from "vue-property-decorator";
import CategoryListItem from "@/main/components/category/CategoryListItem.vue";
import {CategoryEntity} from "@/main/types/entity";
import MySiteVue from "@/main/components/MySiteVue";
import SiteUtils from "@/main/service/SiteUtils";

@Component({
    components: {
        CategoryListItem
    }
})
export default class CategoryList extends MySiteVue {

    @Prop({required: true, type: Array})
    readonly categories!: CategoryEntity[];

    @Prop({required: false, type: Boolean, default: false})
    readonly isLoading!: boolean;

    get
    sortedCategories(): CategoryEntity[] {
        return SiteUtils.sortCategories(this.categories || []);
    }
}
