
import {Component, Watch} from "vue-property-decorator";
import {OrderPaymentMethod} from "@/common/constants/OrderPaymentMethod";
import {Getter, Mutation, State} from "vuex-class";
import {MutationType} from "@/main/constants/MutationType";
import Utils from "@/common/service/Utils";
import {OrderFormFormState} from "@/main/types/store";
import BasketProductsTable from "@/main/components/basket/BasketProductsTable.vue";
import SelectDeliveryService from "@/main/components/form/SelectDeliveryService.vue";
import StringInputField from "@/common/element/form/StringInputField.vue";
import MySiteVue from "@/main/components/MySiteVue";

@Component({
    components: {
        BasketProductsTable,
        SelectDeliveryService,
        StringInputField
    }
})
export default class PaymentMethodBlock extends MySiteVue {

    @Mutation(MutationType.ORDER_UPDATE)
    readonly orderUpdate!: (data: object) => null;

    @Getter('getOrderFormData')
    readonly formData!: OrderFormFormState;

    @State(state => state.basket.productsQuantity)
    readonly productsQuantity!: number;

    private selectedPaymentMethod: OrderPaymentMethod = OrderPaymentMethod.CashPayment;

    created() {
        this.onChangeField(this.selectedPaymentMethod);
    }

    onChangeField(value: OrderPaymentMethod) {
        this.orderUpdate({
            paymentMethod: value
        });
    }

    onContinue() {
        this.$emit('onContinue');
    }

    getPaymentMethodDescription(paymentMethod: OrderPaymentMethod) {
        switch (paymentMethod) {
            case OrderPaymentMethod.CashPayment:
                return 'Оплата наличными при получении';
            case OrderPaymentMethod.ToCreditCard:
                return 'Оплата на карту используя онлайн банкинг или ваше мобильное устройство';
            case OrderPaymentMethod.VisaMasterCard:
                return 'Онлайн оплата с вашей кредитной карты';
        }
    }

    get
    paymentMethods(): { id: string, name: string }[] {
        return OrderPaymentMethod.toSelectList()
            .filter(item => item.id !== OrderPaymentMethod.VisaMasterCard);
    }

    @Watch('selectedPaymentMethod')
    selectedPaymentMethodWatcher(value: OrderPaymentMethod) {
        this.onChangeField(value);
    }
}
