
import {Component, Prop} from "vue-property-decorator";
import ProductGridItem from "@/main/components/product/productItem/ProductGridItem.vue";
import ProductListItem from "@/main/components/product/productItem/ProductListItem.vue";
import ProductsListControlBar from "@/main/components/product/list/ProductsListControlBar.vue";
import Spinner from "@/common/element/Spinner.vue";
import {ProductEntity} from "@/main/types/entity";
import {Action, State} from "vuex-class";
import {ActionType} from "@/main/constants/ActionType";
import {ProductGridType} from "@/main/constants/ProductGridType";
import MySiteVue from "@/main/components/MySiteVue";

@Component({
    components: {
        ProductGridItem,
        ProductListItem,
        ProductsListControlBar,
        Spinner
    }
})
export default class ProductsList extends MySiteVue {

    @Prop({required: false, type: Boolean, default: true})
    readonly isShowControlBar!: boolean;

    @Action(ActionType.PRODUCTS_LIST_LOAD)
    readonly loadMore!: () => void;

    @State(state => state.productsList.list)
    readonly productsList!: ProductEntity[];

    @State(state => state.productsList.isMore)
    readonly isShowMoreProducts!: boolean;

    @State(state => state.productsList.isWasFirstLoad)
    readonly isWasFirstLoad!: boolean;

    @State(state => state.productsList.isLoading)
    readonly isLoading!: boolean;

    @State(state => state.filter.isInitialized)
    readonly isFilterInitialized!: boolean;

    @State(state => state.productsList.gridType)
    readonly listGridType!: ProductGridType;

    get
    isShowListEmpty() {
        return this.productsList.length === 0 && !this.isLoading;
    }

    get
    ProductGridType() {
        return ProductGridType;
    }

    get
    gridType(): ProductGridType {
        return this.isShowControlBar ?this.listGridType : ProductGridType.GRID;
    }
}
