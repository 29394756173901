

import {Component} from "vue-property-decorator";
import {Getter} from "vuex-class";
import HeaderBottom from "@/main/components/common/header/HeaderBottom.vue";
import HeaderUserMenu from "@/main/components/common/HeaderUserMenu.vue";
import MySiteVue from "../../MySiteVue";

@Component({
    components: {
        HeaderBottom,
        HeaderUserMenu
    }
})
export default class Header extends MySiteVue {
}
