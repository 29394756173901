

import {Component, Ref, Watch} from "vue-property-decorator";
import {IValidatedField} from "@/common/element/form/IValidatedField";
import SiteApiService from "@/main/service/SiteApiService";
import CustomModal from "@/common/modal/CustomModal.vue";
import NumberInputField from "@/common/element/form/NumberInputField.vue";
import {Action, Mutation, State} from "vuex-class";
import {MutationType} from "@/main/constants/MutationType";
import {ActionType} from "@/main/constants/ActionType";
import MySiteVue from "@/main/components/MySiteVue";
import {CustomModalButton} from "@/common/type/system";
import StringInputField from "@/common/element/form/StringInputField.vue";
import { RegExConstants } from "@/common/constants/RegExConstants";

enum ModalState {
    Step1,
    Step2
}

@Component({
    components: {
        CustomModal,
        StringInputField,
        NumberInputField
    }
})
export default class UserLoginModal extends MySiteVue {

    @Mutation(MutationType.USER_SET_IS_LOGGED_IN)
    readonly setUserLoggedIn!: (isLogged: boolean) => void;

    @Action(ActionType.APP_INIT)
    readonly appInit!: () => void;

    @State(state => state.user.isShowRegistrationModal)
    readonly isShowModal!: boolean;

    private email: string = '';
    private verificationNumber: string = '';
    private modalState: ModalState = ModalState.Step1;
    private isLoading: boolean = false;
    private errorMessage: string = '';

    private emailRegExp = RegExConstants.email;

    @Ref()
    readonly emailNumberField!: IValidatedField;

    @Ref()
    readonly verificationNumberField!: IValidatedField;

    async sendVerificationEmail() {
        this.errorMessage = '';
        if (!this.emailNumberField.validate())
        {
            return;
        }
        this.isLoading = true;
        const response = await SiteApiService.userRegistrationStep1(this.email);
        if (response.isSuccess) {
            this.modalState = ModalState.Step2;
        } else {
            this.isLoading = false;
            this.errorMessage = response.message;
        }
        this.isLoading = false;
    }

    closeModal() {
        this.hideLoginModal();
    }

    toStep1() {
        this.modalState = ModalState.Step1;
    }

    get
    modalButtons(): CustomModalButton[] {
        let buttons: CustomModalButton[] = [
            {
                titleId: 'Отмена',
                callback: () => this.closeModal(),
                btnClass: 'btn btn-default btn-lg'
            }
        ];
        if (this.modalState === ModalState.Step1) {
            buttons.push(
                {
                    titleId: 'Отправить',
                    callback: () => this.sendVerificationEmail(),
                    btnClass: 'w-btn-green btn-lg'
                }
            );
        }
        return buttons;
    }

    get
    isStep1() {
        return this.modalState === ModalState.Step1
    }

    get
    isStep2() {
        return this.modalState === ModalState.Step2
    }

    @Watch('isShowModal')
    isShowModalWatcher(value: boolean) {
        if (value) {
            this.modalState = ModalState.Step1;
            this.email = '';
            this.verificationNumber = '';
        }
    }
}
