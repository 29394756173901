
import {Component, Prop} from "vue-property-decorator";
import {BrandEntity} from "@/main/types/entity";
import MySiteVue from "@/main/components/MySiteVue";

@Component({
    components: {}
})
export default class BrandsListItem extends MySiteVue {

    @Prop({required: true, type: Object})
    readonly brand!: BrandEntity;

    get
    imageUrl() {
        if (this.brand.images.length > 0) {
            return this.brand.images[0].thumbnailUrl;
        }
        return '/images/product_default.png';
    }
}
