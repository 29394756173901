

import {Component, Prop, Watch} from "vue-property-decorator";
import CustomModal from "@/common/modal/CustomModal.vue";
import MySiteVue from "@/main/components/MySiteVue";
import * as _ from 'lodash';

@Component({
    components: {
        CustomModal
    }
})
export default class ImageViewerModal extends MySiteVue {
    @Prop({required: true, type: Boolean})
    readonly isShow!: boolean;

    @Prop({required: true, type: Array})
    readonly imageUrls!: string[];

    @Prop({required: true, type: String})
    readonly title!: string;

    @Prop({required: false, type: String, default: ''})
    readonly selectedImageUrl!: string;

    private isLoading: boolean = false;
    private currentImageIndex: number = 0;

    closeModal() {
        this.$emit('onClose');
    }

    onNext() {
        if (this.imageUrls.length <= 1) {
            return;
        }
        this.isLoading = true;
        if (this.imageUrls[this.currentImageIndex + 1]) {
            this.currentImageIndex++;
        } else {
            this.currentImageIndex = 0;
        }
    }

    onImageLoaded() {
        this.isLoading = false;
    }

    get
    currentImageUrl() {
        return this.imageUrls[this.currentImageIndex];
    }

    get
    isOneImage() {
        return this.imageUrls.length > 0;
    }

    @Watch('isShow')
    isShowWatcher(isShow: boolean) {
        if (isShow) {
            if (this.selectedImageUrl &&  this.imageUrls) {
                this.currentImageIndex = _.indexOf(this.imageUrls, this.selectedImageUrl);
            }
        }
    }
}
