

import {Component} from "vue-property-decorator";
import BasketProductsTable from "@/main/components/basket/BasketProductsTable.vue";
import CustomModal from "@/common/modal/CustomModal.vue";
import QuantityInputField from "@/common/element/form/QuantityInputField.vue";
import {MutationType} from "@/main/constants/MutationType";
import {Mutation, State} from "vuex-class";
import MySiteVue from "@/main/components/MySiteVue";
import {CustomModalButton} from "@/common/type/system";

@Component({
    components: {
        BasketProductsTable,
        CustomModal,
        QuantityInputField
    }
})
export default class BasketModal extends MySiteVue {

    @State(state => state.basket.productsQuantity)
    readonly productsQuantity!: number;

    @State(state => state.basket.isLoading)
    readonly isBasketDataLoading!: boolean;

    @State(state => state.basket.isShowBasketModal)
    readonly isShowBasketModal!: boolean;

    @Mutation(MutationType.BASKET_CLOSE_MODAL)
    readonly closeModal!: () => void;

    private onClickCheckout() {
        this.closeModal();
        this.$router.push('/order');
    }

    get
    modalButtons(): CustomModalButton[] {
        let buttons: CustomModalButton[] = [{
            titleId: 'Продолжить покупки',
            callback: () => this.closeModal(),
            btnClass: 'btn w-btn-blue btn-lg'
        }];
        if (this.productsQuantity > 0) {
            buttons.push(
                {
                    titleId: 'Оформить заказ',
                    callback: () => this.onClickCheckout(),
                    btnClass: 'w-btn-green btn-lg'
                }
            );
        }
        return buttons;
    }
}
