import {OrderFormFormState, OrderFormState, RootState} from "@/main/types/store";
import {Module} from "vuex";
import {MutationType} from "@/main/constants/MutationType";

const defaultValues = new OrderFormFormState;

const module: Module<OrderFormState, RootState> = {
    state: new OrderFormState,
    mutations: {
        [MutationType.ORDER_UPDATE] (state, data) {
            state.form = { ...state.form, ...data };
        },
        [MutationType.ORDER_RESET] (state) {
            state.form = { ...defaultValues };
        }
    },
    getters: {
        getOrderFormData: state => {
            return state.form
        }
    },
    actions: {}
};

export default module;
