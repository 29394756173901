export enum ActionType {
    APP_INIT= 'APP_INIT',

    FILTER_LOAD_DATA= 'FILTER_LOAD_DATA',

    USER_LOGOUT= 'USER_LOGOUT',

    PRODUCTS_LIST_LOAD= 'PRODUCTS_LIST_LOAD',
    PRODUCTS_LIST_RELOAD= 'PRODUCTS_LIST_RELOAD',
    PRODUCTS_LIST_LOAD_SHOWCASE= 'PRODUCTS_LIST_LOAD_SHOWCASE',
    PRODUCTS_LIST_LOAD_CATEGORIES_SHOWCASE= 'PRODUCTS_LIST_LOAD_CATEGORIES_SHOWCASE',
    PRODUCTS_WISHLIST_SET= 'PRODUCTS_WISHLIST_SET',

    PRODUCT_LOAD= 'PRODUCT_LOAD',

    BASKET_LOAD_AND_UPDATE= 'BASKET_LOAD_AND_UPDATE',
    BASKET_ADD_REMOVE_PRODUCT= 'BASKET_ADD_REMOVE_PRODUCT',

    DELIVERY_SERVICES_LOAD= 'DELIVERY_SERVICES_LOAD',

    BRANDS_LIST_LOAD= 'BRANDS_LIST_LOAD',
}
