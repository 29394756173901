export enum MutationType {
    APP_IS_LOADING= 'APP_IS_LOADING',
    APP_SET_IS_INITIALIZED= 'APP_SET_IS_INITIALIZED',

    USER_UPDATE= 'USER_UPDATE',
    USER_SHOW_LOGIN_MODAL= 'USER_SHOW_LOGIN_MODAL',
    USER_HIDE_LOGIN_MODAL= 'USER_HIDE_LOGIN_MODAL',
    USER_SET_IS_LOGGED_IN= 'USER_SET_IS_LOGGED_IN',
    USER_SET_IS_LOGOUT= 'USER_SET_IS_LOGOUT',
    USER_SHOW_REGISTRATION_MODAL= 'USER_SHOW_REGISTRATION_MODAL',
    USER_HIDE_REGISTRATION_MODAL= 'USER_HIDE_REGISTRATION_MODAL',

    FILTER_INIT= 'FILTER_INIT',
    FILTER_CHANGE= 'FILTER_CHANGE',
    FILTER_SET_SORT_TYPE= 'FILTER_SET_SORT_TYPE',
    FILTER_RESET= 'FILTER_RESET',
    FILTER_PARTIAL_RESET= 'FILTER_PARTIAL_RESET',
    FILTER_IS_LOADING= 'FILTER_IS_LOADING',

    PRODUCTS_LIST_UPDATE= 'PRODUCTS_LIST_UPDATE',
    PRODUCTS_LIST_SET_LOADING= 'PRODUCTS_LIST_SET_LOADING',
    PRODUCTS_LIST_SET_VIEW_DATA= 'PRODUCTS_LIST_SET_VIEW_DATA',
    PRODUCTS_LIST_SET_GRID_TYPE= 'PRODUCTS_LIST_SET_GRID_TYPE',
    PRODUCTS_LIST_RESET= 'PRODUCTS_LIST_RESET',
    PRODUCTS_LIST_UPDATE_PRODUCT= 'PRODUCTS_LIST_UPDATE_PRODUCT',
    PRODUCTS_LIST_UPDATE_CATEGORY_SHOWCASE= 'PRODUCTS_LIST_UPDATE_CATEGORY_SHOWCASE',

    PRODUCT_SET= 'PRODUCT_SET',
    PRODUCT_SET_LOADING= 'PRODUCT_SET_LOADING',

    BASKET_UPDATE= 'BASKET_UPDATE',
    BASKET_IS_LOADING= 'BASKET_IS_LOADING',
    BASKET_CLOSE_MODAL= 'BASKET_CLOSE_MODAL',
    BASKET_SHOW_MODAL= 'BASKET_SHOW_MODAL',
    BASKET_RESET= 'BASKET_RESET',

    ORDER_UPDATE= 'ORDER_UPDATE',
    ORDER_RESET= 'ORDER_RESET',

    DELIVERY_SERVICES_UPDATE= 'DELIVERY_SERVICES_UPDATE',
    DELIVERY_SERVICES_IS_LOADING= 'DELIVERY_SERVICES_IS_LOADING',

    CATEGORIES_SET_LIST= 'CATEGORIES_SET_LIST',

    BRANDS_SET_TOP= 'BRANDS_SET_TOP',
    BRANDS_LIST_UPDATE= 'BRANDS_LIST_UPDATE',
    BRANDS_LIST_SET_LOADING= 'BRANDS_LIST_SET_LOADING',
    BRANDS_LIST_RESET= 'BRANDS_LIST_RESET',

    BROWSER_META_SET= 'BROWSER_META_SET',
}
