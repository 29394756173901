
import {Component, Prop, Watch} from "vue-property-decorator";
import {DeliveryServiceEntity, DeliveryTypeEntity} from "@/main/types/entity";
import {Action, Getter, State} from "vuex-class";
import {ActionType} from "@/main/constants/ActionType";
import * as _ from 'lodash';
import Multiselect from "vue-multiselect";
import Utils from "@/common/service/Utils";
import MySiteVue from "@/main/components/MySiteVue";

@Component({
    components: {
        Multiselect
    }
})
export default class SelectDeliveryService extends MySiteVue {

    @Prop({required: false, type: Boolean, default: false})
    readonly isRequired!: string;

    @Prop({required: false, type: String, default: ''})
    readonly deliveryServiceLabel!: string;

    @Prop({required: false, type: String, default: ''})
    readonly deliveryTypeLabel!: string;

    @Action(ActionType.DELIVERY_SERVICES_LOAD)
    readonly deliveryServicesLoad!: () => void;

    @Getter('getDeliveryServices')
    readonly deliveryServices!: DeliveryServiceEntity[];

    @State(state => state.deliveryService.isLoading)
    readonly isLoading!: boolean;

    private selectedDeliveryService: DeliveryServiceEntity | null = null;
    private selectedDeliveryType: DeliveryTypeEntity | null = null;
    private isDeliveryServiceError: boolean = false;
    private isDeliveryTypeError: boolean = false;

    created() {
        if (this.deliveryServices.length === 0) {
            this.deliveryServicesLoad();
        }
    }

    validate() {
        if (this.isRequired) {
            this.isDeliveryServiceError = !this.selectedDeliveryService;
            this.isDeliveryTypeError = !this.selectedDeliveryService || !this.selectedDeliveryType;
        }
        return !this.isDeliveryTypeError;
    }

    get
    deliveryTypes() {
        return _.get(this.selectedDeliveryService, 'deliveryTypes', []);
    }

    get
    deliveryServiceComputedLabel() {
        if (this.deliveryServiceLabel == null) {
            return 'Служба доставки';
        }
        return this.deliveryServiceLabel;
    }

    get
    deliveryTypeComputedLabel() {
        if (this.deliveryTypeLabel == null) {
            return 'Способ доставки';
        }
        return this.deliveryTypeLabel;
    }

    @Watch('selectedDeliveryService')
    selectedDeliveryServiceWatcher() {
        this.selectedDeliveryType = null;
        this.validate();
    }

    @Watch('selectedDeliveryType')
    selectedDeliveryTypeWatcher(value: DeliveryTypeEntity) {
        this.validate();
        this.$emit('change', value);
    }
}
