
import {Component, Prop, Watch} from "vue-property-decorator";
import MySiteVue from "../components/MySiteVue";
import PageTitle from "../components/common/PageTitle.vue";
import ProductsList from "../components/product/list/ProductsList.vue";
import FilterForm from "../components/filter/FilterForm.vue";
import { Action, Mutation, State } from "vuex-class";
import {MutationType} from "@/main/constants/MutationType";
import {FilterSelectedState} from "@/main/types/store";
import * as _ from 'lodash';
import { BrandEntity, ProductEntity } from "@/main/types/entity";
import Utils from "@/common/service/Utils";
import { ActionType } from "@/main/constants/ActionType";
import CategoryShowcaseList from "@/main/components/product/list/CategoryShowcaseList.vue";

@Component({
    components: {
        PageTitle,
        ProductsList,
        FilterForm,
        CategoryShowcaseList
    }
})
export default class ProductsFilterPage extends MySiteVue {
    @Prop({required: false, type: String, default: ''})
    readonly title!: string;

    @Mutation(MutationType.FILTER_RESET)
    readonly filterReset!: () => void;

    @Mutation(MutationType.PRODUCTS_LIST_RESET)
    readonly productsListReset!: () => void;

    @Mutation(MutationType.FILTER_CHANGE)
    readonly filterChange!: (data: FilterSelectedState) => void;

    @State(state => state.productsList.viewData.brand)
    readonly brand!: BrandEntity;

    @State(state => state.productsList.list)
    readonly productsList!: ProductEntity[];

    private categoryShortName: string = '';
    private brandShortName: string = '';
    private searchString: string = '';

    get pageTitle() {
        if (this.searchString) {
            return `Результаты поиска: "this.searchString"`;
        }
        if (this.category) {
            return this.category.name;
        }
        if (this.brand) {
            return this.brand.name;
        }
        return this.title;
    }

    created() {
        this.filterReset();
        this.productsListReset();
        this.updateVarsFromRoute();

        if (this.searchString) {
            this.filterChange({
                searchString: this.searchString
            } as FilterSelectedState);
        }
        this.setSeoFields();
    }

    updateVarsFromRoute() {
        this.categoryShortName = _.get(this.$route, 'params.shortName');
        this.brandShortName = _.get(this.$route, 'params.brandShortName');
        this.searchString = _.get(this.$route, 'params.searchString');
    }

    get category() {
        return this.getCategoryById(this.categoryShortName);
    }

    @Watch('$route.path')
    routePathWatcher(path: string) {
        this.productsListReset();
        this.updateVarsFromRoute();
    }

    @Watch('productsList')
    productsListWatcher() {
        this.setSeoFields();
    }

    @Watch('brand')
    brandWatcher() {
        this.setSeoFields();
    }

    private setSeoFields() {
        let title;
        let description;
        let keywords;
        if (this.searchString) {
            title = `Результаты поиска: "this.searchString"`;
        }
        if (this.category) {
            title = this.category.seoTitle;
            description = this.category.seoDescription;
            keywords = `${this.category.seoKeywords} ${this.category.seoKeywordsAdditional}`;
        }

        if (this.brand) {
            title = this.brand.seoTitle;
            description = this.brand.seoDescription;
            keywords = this.brand.seoKeywords;
            if (!title) {
                title = `Продукты бренда: ${this.brand.name} - ElTehnika.com | Купить в Украине: цена, отзывы, продажа`;
                description = `Продукты бренда: ${this.brand.name} - ElTehnika.com | Купить в Украине: цена, отзывы, продажа`;
            }
        }

        this.$store.commit(
            MutationType.BROWSER_META_SET,
            {
                seoTitle: title,
                seoDescription: description,
                seoKeywords: keywords
            }
        );
    }
}
