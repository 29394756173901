

import {Component, Watch} from "vue-property-decorator";
import HeaderSearchField from "@/main/components/common/header/HeaderSearchField.vue";
import CategoriesMenu from "@/main/components/common/category/CategoriesMenu.vue";
import BasketInfoBlock from "@/main/components/basket/BasketInfoBlock.vue";
import HeaderUserMenu from "@/main/components/common/HeaderUserMenu.vue";
import MySiteVue from "@/main/components/MySiteVue";

@Component({
    components: {
        HeaderSearchField,
        CategoriesMenu,
        BasketInfoBlock,
        HeaderUserMenu
    }
})
export default class HeaderBottom extends MySiteVue {

    private isShowMenu: boolean = false;
    private searchString: string | null = '';

    mounted() {
        document.addEventListener('click', this.onClickOutside);
    }

    beforeDestroy() {
        document.removeEventListener('click', this.onClickOutside);
    }

    onClickOutside() {
        this.isShowMenu = false;
    }

    @Watch('searchString')
    searchStringWatcher(searchString: string) {
        // SiteApi.filterQuickSearch(value, (response) => {
        //     console.log(22, response)
        // });
    }
}
