

import {Component, Prop} from "vue-property-decorator";
import MySiteVue from "@/main/components/MySiteVue";
import {ProductCommentEntity} from "@/main/types/entity";
import CommentEntitySchema from "@/main/page/product/CommentEntitySchema.vue";
import Rating from "@/main/components/form/Rating.vue";

@Component({
    components: {
        CommentEntitySchema,
        Rating
    }
})
export default class CommentItem extends MySiteVue {
    @Prop({required: true, type: Object})
    readonly comment!: ProductCommentEntity;
}
