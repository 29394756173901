
import {Component, Prop} from "vue-property-decorator";
import MySiteVue from "../../components/MySiteVue";
import {CommonImageEntity, ProductEntity} from "../../types/entity";
import * as _ from 'lodash';
import ImageViewerModal from "@/main/components/modal/ImageViewerModal.vue";

@Component({
    components: {
        ImageViewerModal
    }
})
export default class ProductImagesBlock extends MySiteVue {
    @Prop({required: true, type: Object})
    readonly product!: ProductEntity;

    private bigImageUrl: string = '';
    private isBigImageLoading: boolean = false;
    private isShowImageViewer: boolean = false;

    created() {
        this.bigImageUrl = this.firstBigImageUrl;
    }

    onClickBigImage() {
        if (this.isBigImageLoading) {
            return;
        }
        this.isShowImageViewer = true;
    }

    onSelectSmallImage(imageUrl: string) {
        if (this.isOneImage) {
            return;
        }
        this.bigImageUrl = imageUrl;
        this.isBigImageLoading = true;
    }

    onBigImageLoaded() {
        this.isBigImageLoading = false;
    }

    get
    firstBigImageUrl() {
        if (this.hasImages) {
            return _.first(this.product.images)!.url;
        }
        return '/images/product_default.png';
    }

    get
    images(): CommonImageEntity[] {
        return this.product.images;
    }

    get
    hasImages() {
        return this.product.images.length > 0;
    }

    get
    isOneImage() {
        return this.product.images.length === 1;
    }

    get
    bigImages() {
        return _.map(this.product.images, 'url');
    }
}
