import { render, staticRenderFns } from "./OrderForm.vue?vue&type=template&id=c1e81b5e&scoped=true&lang=pug&"
import script from "./OrderForm.vue?vue&type=script&lang=ts&"
export * from "./OrderForm.vue?vue&type=script&lang=ts&"
import style0 from "./OrderForm.vue?vue&type=style&index=0&id=c1e81b5e&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c1e81b5e",
  null
  
)

export default component.exports