import {AppState, ProductState, RootState} from "@/main/types/store";
import {Module} from "vuex";
import {MutationType} from "@/main/constants/MutationType";
import {ActionType} from "@/main/constants/ActionType";
import SiteApiService from "@/main/service/SiteApiService";

const module: Module<ProductState, RootState> = {
    state: new ProductState,
    mutations: {
        [MutationType.PRODUCT_SET] (state, data) {
            state.isLoading = false;
            state.data = data;
            state.isLoaded = true;
        },
        [MutationType.PRODUCT_SET_LOADING] (state, isLoading) {
            state.isLoaded = false;
            state.isLoading = isLoading;
        },
    },
    getters: {
        getCurrentProduct(state) {
            return state.data;
        },
    },
    actions: {
        [ActionType.PRODUCT_LOAD]: async ({ commit }, productShortName) => {
            commit(MutationType.PRODUCT_SET_LOADING, true);

            const response = await SiteApiService.productGet(productShortName);
            const product = response?.data;
            if (response.isSuccess) {
                commit(MutationType.PRODUCT_SET, response.data);
            } else {
                commit(MutationType.PRODUCT_SET, null)
            }

            commit(
                MutationType.BROWSER_META_SET,
                {
                    seoTitle: product?.seoTitle,
                    seoDescription: product?.seoDescription,
                    seoKeywords: product?.seoKeywords
                }
            );
        },
    }
};

export default module;
