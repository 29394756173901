
import {Component} from "vue-property-decorator";
import MySiteVue from "../components/MySiteVue";
import {Action, Getter, Mutation} from "vuex-class";
import {ActionType} from "../constants/ActionType";
import {MutationType} from "../constants/MutationType";
import {CategoryEntity} from "@/main/types/entity";
import Spinner from "@/common/element/Spinner.vue";
import PageTitle from "@/main/components/common/PageTitle.vue";
import CategoryList from "@/main/components/category/CategoryList.vue";
import ProductsList from "@/main/components/product/list/ProductsList.vue";
import CategoriesMenu from "@/main/components/common/category/CategoriesMenu.vue";

@Component({
    components: {
        Spinner,
        PageTitle,
        CategoryList,
        ProductsList,
        CategoriesMenu
    }
})
export default class HomePage extends MySiteVue {

    @Action(ActionType.PRODUCTS_LIST_LOAD_SHOWCASE)
    readonly loadShowcase!: () => void;

    @Mutation(MutationType.PRODUCTS_LIST_RESET)
    readonly listReset!: () => void;

    @Getter('getRootCategories')
    readonly rootCategories!: CategoryEntity[];

    @Getter('isAppInitialized')
    readonly isAppInitialized!: boolean;

    mounted() {
        this.listReset();
        this.loadShowcase();
        this.$store.commit(
            MutationType.BROWSER_META_SET,
            {
                seoTitle: 'Интернет-магазин Бытовой техники и электроники в Донецке(ДНР)',
                seoDescription: 'Интернет-магазин Бытовой техники и электроники в Донецке(ДНР)',
                seoKeywords: ''
            }
        );
    }
}
