import {CategoryEntity} from "@/main/types/entity";
import * as _ from "lodash";

export default class SiteUtils {

    public static getCategoryUrl(category: CategoryEntity): string {
        if (category.children && category.children.length > 0) {
            return '/category/' + category.url;
        }
        return '/category/products/' + category.url;
    }

    public static sortCategories(categories: CategoryEntity[]): CategoryEntity[] {
        return _.orderBy(categories, ['position'], ['desc']);
    }
}
