import { RouteConfig } from "vue-router";
import HomePage from "@/main/page/HomePage.vue";
import Utils from "@/common/service/Utils";
import ShippingAndDeliveryPage from "@/main/page/info/shippingAndDelivery/ShippingAndDeliveryPage.vue";
import ContactsPage from "@/main/page/info/ContactsPage.vue";
import UserWishListPage from "@/main/page/UserWishListPage.vue";
import WarrantyPage from "@/main/page/info/WarrantyPage.vue";
import Error404Page from "@/main/page/error/Error404Page.vue";
import BrandsListPage from "@/main/page/brand/BrandsListPage.vue";
import UserOrdersListPage from "@/main/page/UserOrdersListPage.vue";
import ProductPage from "@/main/page/product/ProductPage.vue";
import ProductsFilterPage from "@/main/page/ProductsFilterPage.vue";
import OrderSentPage from "@/main/page/order/OrderSentPage.vue";
import EmailVerificationPage from "@/main/page/EmailVerificationPage.vue";
import OrderPaymentInstructionsPage from "@/main/page/order/OrderPaymentInstructionsPage.vue";
import OrderPage from "@/main/page/OrderPage.vue";
import CategoryChildrenPage from "@/main/page/category/CategoryChildrenPage.vue";

const routes: RouteConfig[] = [
    {
        path: '/',
        component: HomePage,
        meta: {
            title: 'Интернет-магазин eltehnika.net: официальный сайт популярного онлайн-магазина в Украине',
        }
    },
    {
        path: '/info/shipping-and-delivery',
        component: ShippingAndDeliveryPage,
        meta: {
            title: 'Доставка и оплата',
        }
    },
    {
        path: '/info/warranty',
        component: WarrantyPage,
        meta: {
            title: 'Гарантия',
        }
    },
    {
        path: '/error404',
        component: Error404Page,
        meta: {
            title: 'Error 404',
        }
    },
    {
        path: '/brands',
        component: BrandsListPage,
        meta: {
            title: 'Все бренды от А до Я в интернет магазине eltehnika.net',
            metaTags: [
                {
                    name: 'description',
                    content: 'Техника от лучших брендов в интернет-магазине ➦ eltehnika.net. Лучшие цены, быстрая доставка, гарантия!',
                },
                {
                    property: 'keywords',
                    content: '',
                }
            ]
        }
    },
    {
        path: '/order',
        component: OrderPage,
        meta: {
            title: 'Информация о заказе',
        }
    },
    {
        path: '/contacts',
        component: ContactsPage,
        meta: {
            title: 'Наши контакты',
        }
    },
    {
        path: '/user/orders',
        component: UserOrdersListPage,
        meta: {
            title: 'Мои заказы',
        }
    },
    {
        path: '/user/wishList',
        component: UserWishListPage,
        meta: {
            title: 'Список желаний',
        }
    },

    {path: '/product/:productShortName', component: ProductPage},
    {path: '/brand/products/:brandShortName', component: ProductsFilterPage},
    {
        path: '/search/:searchString',
        component: ProductsFilterPage,
        meta: {
            title: 'Результаты поиска',
        }
    },
    {path: '/category/products/:shortName', component: ProductsFilterPage},

    {
        path: '/order/sent/:orderNumber',
        component: OrderSentPage,
        meta: {
            title: 'Спасибо за покупку',
        }
    },
    {
        path: '/order/payment_instructions/:orderNumber',
        component: OrderPaymentInstructionsPage,
        meta: {
            title: 'Информация об оплате'
        }
    },

    {path: '/category/:shortName', component: CategoryChildrenPage},
    {path: '/email/verification/:token', component: EmailVerificationPage},
];

export default routes;
