

import {Component, Ref, Watch} from "vue-property-decorator";
import {IValidatedField} from "@/common/element/form/IValidatedField";
import SiteApiService from "@/main/service/SiteApiService";
import * as _ from 'lodash';
import CustomModal from "@/common/modal/CustomModal.vue";
import PhoneNumberInputField from "@/common/element/form/PhoneNumberInputField.vue";
import NumberInputField from "@/common/element/form/NumberInputField.vue";
import {Action, Mutation, State} from "vuex-class";
import {MutationType} from "@/main/constants/MutationType";
import {ActionType} from "@/main/constants/ActionType";
import MySiteVue from "@/main/components/MySiteVue";
import {CustomModalButton} from "@/common/type/system";

enum ModalState {
    Step1,
    Step2
}

@Component({
    components: {
        CustomModal,
        PhoneNumberInputField,
        NumberInputField
    }
})
export default class UserLoginModal extends MySiteVue {

    @Mutation(MutationType.USER_SET_IS_LOGGED_IN)
    readonly setUserLoggedIn!: (isLogged: boolean) => void;

    @Action(ActionType.APP_INIT)
    readonly appInit!: () => void;

    @State(state => state.user.isShowLoginModal)
    readonly isShowModal!: boolean;

    private phoneNumber: string = '';
    private verificationNumber: string = '';
    private modalState: ModalState = ModalState.Step1;
    private isLoading: boolean = false;
    private errorMessage: string = '';
    private requestToken: string = '';

    @Ref()
    readonly phoneNumberField!: IValidatedField;

    @Ref()
    readonly verificationNumberField!: IValidatedField;

    async sendVerificationCode() {
        this.errorMessage = '';
        if (!this.phoneNumberField.validate())
        {
            return;
        }
        this.isLoading = true;
        const response = await SiteApiService.userLoginStep1(this.phoneNumber);
        if (response.isSuccess) {
            this.requestToken = response.data.requestToken;
            this.modalState = ModalState.Step2;
        } else {
            this.isLoading = false;
            this.errorMessage = response.message;
        }
        this.isLoading = false;
    }

    async login() {
        this.errorMessage = '';
        if (!this.verificationNumberField.validate() || !this.requestToken)
        {
            return;
        }
        this.isLoading = true;
        const response = await SiteApiService.userLoginStep2(this.verificationNumber, this.requestToken);
        if (response.isSuccess) {
            this.setUserLoggedIn(true);
            this.appInit();
            this.closeModal();
        } else {
            this.errorMessage = response.message;
            this.isLoading = false;
        }
        this.isLoading = false;
    }

    closeModal() {
        this.hideLoginModal();
    }

    toStep1() {
        this.requestToken = '';
        this.modalState = ModalState.Step1;
    }

    get
    modalButtons(): CustomModalButton[] {
        let buttons: CustomModalButton[] = [
            {
                titleId: 'Отмена',
                callback: () => this.closeModal(),
                btnClass: 'btn btn-default btn-lg'
            }
        ];
        if (this.modalState === ModalState.Step1) {
            buttons.push(
                {
                    titleId: 'Отправить мне код',
                    callback: () => this.sendVerificationCode(),
                    btnClass: 'w-btn-green btn-lg'
                }
            );
        } else if (this.modalState === ModalState.Step2) {
            buttons.push(
                {
                    titleId: 'Войти',
                    callback: () => this.login(),
                    btnClass: 'w-btn-green btn-lg'
                }
            );
        }
        return buttons;
    }

    get
    isStep1() {
        return this.modalState === ModalState.Step1
    }

    get
    isStep2() {
        return this.modalState === ModalState.Step2
    }

    @Watch('isShowModal')
    isShowModalWatcher(value: boolean) {
        if (value) {
            this.modalState = ModalState.Step1;
            this.phoneNumber = '';
            this.verificationNumber = '';
        }
    }
}
