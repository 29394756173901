
import {Component, Prop} from "vue-property-decorator";
import MySiteVue from "../../components/MySiteVue";
import {ProductEntity} from "../../types/entity";
import Nl2br from "@/common/element/Nl2br.vue";

@Component({
    components: {
        Nl2br
    }
})
export default class ProductDescriptionBlock extends MySiteVue {
    @Prop({required: true, type: Object})
    readonly product!: ProductEntity;
}
