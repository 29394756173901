

import {Component, Prop} from "vue-property-decorator";
import {Action, Getter, Mutation} from "vuex-class";
import {MutationType} from "@/main/constants/MutationType";
import {ActionType} from "@/main/constants/ActionType";
import MySiteVue from "@/main/components/MySiteVue";

@Component({
    components: {}
})
export default class WishListButton extends MySiteVue {

    @Prop({required: true, type: [ Boolean, Number ]})
    readonly isWishlisted!: boolean | number;

    @Prop({required: true, type: Number})
    readonly productId!: number;

    @Action(ActionType.PRODUCTS_WISHLIST_SET)
    readonly wishListSet!: (data: object) => void;

    private isWishlistedProduct: boolean = false;

    created() {
        this.isWishlistedProduct = !!(this.isWishlisted);
    }

    setWishlisted(isWishlisted: boolean) {
        if (!this.isLoggedIn) {
            this.showLoginModal();
            return;
        }
        this.isWishlistedProduct = isWishlisted;
        this.wishListSet({
            productId: this.productId,
            isWishlisted
        });
    }
}
