import Vue from 'vue'
import router from './router'
import store from './store'
import '@/common/index'
import { ActionType } from "@/main/constants/ActionType";
import AppLayout from "@/main/components/AppLayout.vue";
import '@/main/main.less'

new Vue({
    router,
    store,
    data: {},
    render: h => h(AppLayout),
    beforeCreate() {
    },
    created() {
        this.$store.dispatch(ActionType.APP_INIT);
    },
    watch: {},
}).$mount('#app')
