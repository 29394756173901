import Utils from "../service/Utils";

export enum ProductStockAvailabilityType {
    Yes = 'yes',
    No = 'no',
    Order = 'order',
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ProductStockAvailabilityType {
    export function toString(status: ProductStockAvailabilityType): string {
        switch (status) {
            case ProductStockAvailabilityType.Yes:
                return 'В наличии';
            case ProductStockAvailabilityType.No:
                return 'Отсутвует';
            case ProductStockAvailabilityType.Order:
                return 'Под заказ';
        }
    }

    export function toSelectList(): { id: string, name: string }[] {
        return [
            { id: ProductStockAvailabilityType.Yes.toString(), name: toString(ProductStockAvailabilityType.Yes) },
            { id: ProductStockAvailabilityType.No.toString(), name: toString(ProductStockAvailabilityType.No) },
            { id: ProductStockAvailabilityType.Order.toString(), name: toString(ProductStockAvailabilityType.Order) }
        ];
    }
}
