
import {Component, Prop} from "vue-property-decorator";
import ProductGridItem from "@/main/components/product/productItem/ProductGridItem.vue";
import ProductListItem from "@/main/components/product/productItem/ProductListItem.vue";
import ProductsListControlBar from "@/main/components/product/list/ProductsListControlBar.vue";
import Spinner from "@/common/element/Spinner.vue";
import {ProductEntity} from "@/main/types/entity";
import {State} from "vuex-class";
import MySiteVue from "@/main/components/MySiteVue";

@Component({
    components: {
        ProductGridItem,
        ProductListItem,
        ProductsListControlBar,
        Spinner
    }
})
export default class CategoryShowcaseList extends MySiteVue {

    @State(state => state.productsList.categoriesShowcaseList)
    readonly categoriesShowcaseList!: ProductEntity[];
}
