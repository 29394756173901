

import {Component} from "vue-property-decorator";
import {Action, Getter, Mutation} from "vuex-class";
import {ActionType} from "@/main/constants/ActionType";
import MySiteVue from "@/main/components/MySiteVue";

@Component({
    components: {}
})
export default class HeaderUserMenu extends MySiteVue {
    @Action(ActionType.USER_LOGOUT)
    onClickLogout() {}
}
