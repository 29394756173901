

import {Component, Prop} from "vue-property-decorator";
import {ProductCommentEntity} from "@/main/types/entity";
import {Action, Mutation, State} from "vuex-class";
import {ActionType} from "@/main/constants/ActionType";
import {ProductSortType} from "@/main/constants/ProductSortType";
import {MutationType} from "@/main/constants/MutationType";
import {ProductGridType} from "@/main/constants/ProductGridType";
import * as _ from 'lodash';
import Multiselect from "vue-multiselect";
import MySiteVue from "@/main/components/MySiteVue";

@Component({
    components: {
        Multiselect
    }
})
export default class ProductsListControlBar extends MySiteVue {

    @Prop({required: false, type: String, default: ''})
    readonly rootClass!: string;

    @Action(ActionType.PRODUCTS_LIST_RELOAD)
    readonly productsListReload!: () => void;

    @Mutation(MutationType.FILTER_SET_SORT_TYPE)
    readonly setSortType!: (sortType: ProductSortType) => void;

    @Mutation(MutationType.PRODUCTS_LIST_SET_GRID_TYPE)
    readonly setGridType!: (gridType: ProductGridType) => void;

    @State(state => state.productsList.gridType)
    readonly gridType!: ProductGridType;

    @State(state => state.filter.sortType)
    readonly filterSortType!: ProductSortType;

    private comments: ProductCommentEntity[] = [];

    onChangeSortType(selectedValue: any) {
        this.setSortType(selectedValue.value);
        this.productsListReload();
    }

    onSetGridType(type: ProductGridType) {
        this.setGridType(type);
    }

    get
    sortType() {
        return _.find(ProductSortType.getSelectList(), item => item.value === this.filterSortType);
    }

    get
    sortTypes() {
        return ProductSortType.getSelectList()
    }

    get
    ProductGridType() {
        return ProductGridType;
    }
}
