

import {Component, Prop, Ref, Watch} from "vue-property-decorator";
import CheckboxField from "@/common/element/form/CheckboxField.vue";
import Rating from "@/main/components/form/Rating.vue";
import StringInputField from "@/common/element/form/StringInputField.vue";
import InfoBlock from "@/common/element/InfoBlock.vue";
import ErrorBlock from "@/common/element/ErrorBlock.vue";
import CustomModal from "@/common/modal/CustomModal.vue";
import {IValidatedField} from "@/common/element/form/IValidatedField";
import {AddCommentRequestModel} from "@/main/types/apiRequest";
import Utils from "@/common/service/Utils";
import SiteApiService from "@/main/service/SiteApiService";
import {CustomModalButton} from "@/common/type/system";
import MySiteVue from "@/main/components/MySiteVue";

@Component({
    components: {
        CheckboxField,
        Rating,
        StringInputField,
        InfoBlock,
        ErrorBlock,
        CustomModal
    }
})
export default class AddCommentModal extends MySiteVue {
    @Prop({required: true, type: Boolean})
    readonly isShow!: boolean;

    @Prop({required: true, type: Number})
    readonly productId!: number;

    @Prop({required: false, type: Number, default: 0})
    readonly commentId!: number;

    private isLoading: boolean = false;
    private errorMessage: string = '';
    private successMessage: string = '';
    private form: AddCommentRequestModel = new AddCommentRequestModel;

    @Ref()
    readonly commentField!: IValidatedField;

    @Ref()
    readonly userNameField!: IValidatedField;

    @Ref()
    readonly commentDignityField!: IValidatedField;

    @Ref()
    readonly commentShortcomingsField!: IValidatedField;

    created() {
        this.form.productId = this.productId;
    }

    closeModal() {
        this.$emit('onClose');
    }

    resetForm() {
        this.form.rating = 3;
        this.commentField.reset();
        this.userNameField.reset();
        this.commentDignityField.reset();
        this.commentShortcomingsField.reset();
        this.form.isSendNotification = false;
    }

    async onSave() {
        this.errorMessage = '';
        this.successMessage = '';

        const isValid = this.userNameField.validate()
            && this.commentField.validate()
            && this.commentDignityField.validate()
            && this.commentShortcomingsField.validate();
        if (!isValid) {
            return;
        }
        this.isLoading = true;
        const response = await SiteApiService.commentAdd(this.form);
        if (response.isSuccess) {
            this.resetForm();
            this.successMessage = 'Спасибо. Ваш комментарий успешно добавлен на проверку модератором.';
        } else {
            this.isLoading = false;
            this.errorMessage = response.message;
        }
        this.isLoading = false;
    }

    onImageLoaded() {
        this.isLoading = false;
    }

    get
    modalButtons(): CustomModalButton[] {
        return [
            {
                titleId: 'Назад',
                callback: () => this.closeModal(),
                btnClass: 'btn-default btn-lg'
            },
            {
                titleId: 'Отправить',
                callback: () => this.onSave(),
                btnClass: 'w-btn-green btn-lg'
            }
        ];
    }

    @Watch('isShow')
    isShowWatcher(isShow: boolean) {
        if (isShow) {
            // Some code
        }
    }
}
