export enum BreadcrumbsType {
    MAIN= 'MAIN',
    CATEGORY_PAGE= 'CATEGORY_PAGE',
    PRODUCT_PAGE= 'PRODUCT_PAGE',
    BRANDS_PAGE= 'BRANDS_PAGE',
    BRAND_PRODUCTS_PAGE= 'BRAND_PRODUCTS_PAGE',
    CONTACTS_PAGE= 'CONTACTS_PAGE',
    SEARCH_PAGE= 'SEARCH_PAGE',
    ORDER_SENT_PAGE= 'ORDER_SENT_PAGE',

    USER_ORDERS_PAGE= 'USER_ORDERS_PAGE',
    USER_WISHLIST_PAGE= 'USER_WISHLIST_PAGE',
}

export namespace BreadcrumbsType {
    export function getFromRoutePath(path: string): BreadcrumbsType | null {
        if (path === '/') {
            return BreadcrumbsType.MAIN;
        }
        if (path.startsWith('/category/')) {
            return BreadcrumbsType.CATEGORY_PAGE;
        }
        if (path.startsWith('/user/orders')) {
            return BreadcrumbsType.USER_ORDERS_PAGE;
        }
        if (path.startsWith('/user/wishList')) {
            return BreadcrumbsType.USER_WISHLIST_PAGE;
        }
        if (path.startsWith('/order/sent')) {
            return BreadcrumbsType.ORDER_SENT_PAGE;
        }
        if (path.startsWith('/product/')) {
            return BreadcrumbsType.PRODUCT_PAGE;
        }
        if (path.startsWith('/brands')) {
            return BreadcrumbsType.BRANDS_PAGE;
        }
        if (path.startsWith('/brand/products')) {
            return BreadcrumbsType.BRAND_PRODUCTS_PAGE;
        }
        if (path.startsWith('/contacts')) {
            return BreadcrumbsType.CONTACTS_PAGE;
        }
        if (path.startsWith('/search')) {
            return BreadcrumbsType.SEARCH_PAGE;
        }
        return null;
    }
}
