

import {Component, Ref} from "vue-property-decorator";
import {SendFeedbackRequestModel} from "@/main/types/apiRequest";
import InfoBlock from "@/common/element/InfoBlock.vue";
import StringInputField from "@/common/element/form/StringInputField.vue";
import ErrorBlock from "@/common/element/ErrorBlock.vue";
import {IValidatedField} from "@/common/element/form/IValidatedField";
import SiteApiService from "@/main/service/SiteApiService";
import Utils from "@/common/service/Utils";
import MySiteVue from "@/main/components/MySiteVue";
import { MutationType } from "@/main/constants/MutationType";

@Component({
    components: {
        InfoBlock,
        StringInputField,
        ErrorBlock
    }
})
export default class ContactsPage extends MySiteVue {

    private showError: boolean = false;
    private showInfoMessage: boolean = false;
    private form: SendFeedbackRequestModel = new SendFeedbackRequestModel;

    @Ref()
    readonly nameField!: IValidatedField;

    @Ref()
    readonly emailField!: IValidatedField;

    @Ref()
    readonly subjectField!: IValidatedField;

    @Ref()
    readonly messageField!: IValidatedField;

    created() {
        this.$store.commit(
            MutationType.BROWSER_META_SET,
            {
                seoTitle: 'Наши контакты - ElTehnika.com',
                seoDescription: 'Контактные данные - ElTehnika.com',
                seoKeywords: ''
            }
        );
    }

    async send() {
        this.showError = false;

        const isValid = this.nameField.validate()
            && this.emailField.validate()
            && this.subjectField.validate()
            && this.messageField.validate();
        if (!isValid) {
            return;
        }

        const response = await SiteApiService.feedbackSendList(this.form);
        if (response.isSuccess) {
            this.resetForm();
            this.showInfoMessage = true;
        } else {
            this.showError = true;
        }
    }

    resetForm() {
        this.nameField.reset();
        this.emailField.reset();
        this.subjectField.reset();
        this.messageField.reset();
    }

    get
    errorMessage() {
        return [
            'Ошибка. Вы точно не робот?'
        ];
    }

    get
    infoMessage() {
        return [
            'Спасибо! Мы как можно скорее ознакомимся с вашим сообщением'
        ];
    }

    get
    gMapOptions() {
        return {
            zoom: 18,
            scrollwheel: false,
        };
    }

    get
    address() {
        return 'Наш адрес'
    }
}
