
import {Component, Prop} from "vue-property-decorator";
import {BrandEntity, CategoryEntity} from "@/main/types/entity";
import {Getter} from "vuex-class";
import * as _ from 'lodash';
import MySiteVue from "@/main/components/MySiteVue";

@Component({
    components: {}
})
export default class TopBrands extends MySiteVue {

    @Getter('getTopBrands')
    readonly getTopBrands!: BrandEntity[];

    imageUrl(brand: BrandEntity) {
        if (brand.images.length > 0) {
            return brand.images[0].thumbnailUrl;
        }
        return '';
    }

    get
    topBrands() {
        return _.slice(
            _.filter(this.getTopBrands, brand => brand.images.length > 0),
            0,
            8
        )
    }
}
