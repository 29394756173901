
import {Component, Prop} from "vue-property-decorator";
import {ProductEntity} from "@/main/types/entity";
import {ProductStockAvailabilityType} from "@/common/constants/ProductStockAvailabilityType";
import Utils from "@/common/service/Utils";
import BuyButton from "@/main/components/product/BuyButton.vue";
import MySiteVue from "@/main/components/MySiteVue";

@Component({
    components: {
        BuyButton
    }
})
export default class ProductListItem extends MySiteVue {

    @Prop({required: true, type: Object})
    readonly product!: ProductEntity;

    get
    stockAvailabilityClass() {
        let result = ['w-ribbon'];
        switch (this.product.stockAvailability) {
            case ProductStockAvailabilityType.Order:
                result.push('w-blue');
                break;
            case ProductStockAvailabilityType.Yes:
                result.push('w-green');
                break;
            default:
                result.push('w-gray');
        }
        return result;
    }

    get
    stockAvailabilityName() {
        return ProductStockAvailabilityType.toString(this.product.stockAvailability)
    }

    get
    imageUrl() {
        if (this.product.images.length > 0) {
            return this.product.images[0].thumbnailUrl;
        }
        return '/images/product_default.png';
    }
}
