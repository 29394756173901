

import {Component} from "vue-property-decorator";
import Spinner from "../../../common/element/Spinner.vue";
import MySiteVue from "../../components/MySiteVue";
import { MutationType } from "@/main/constants/MutationType";
import * as _ from "lodash";

@Component({
    components: {
        Spinner
    }
})
export default class OrderSentPage extends MySiteVue {

    private orderNumber: number = 0;

    created() {
        this.$store.commit(
            MutationType.BROWSER_META_SET,
            {
                seoTitle: 'Ваш заказ оформлен',
                seoDescription: 'Ваш заказ оформлен. Благодарим за покупку!',
                seoKeywords: ''
            }
        );
    }

    mounted() {
        this.orderNumber = parseInt(_.get(this.$route, 'params.orderNumber'));
        if (!this.orderNumber) {
            this.to404();
            return;
        }
    }

    to404() {
        this.$router.replace('/error404');
    }
}
