
import StringInputField from "./StringInputField.vue";
import {Component, Model, Prop, Ref} from "vue-property-decorator";
import Vue from "vue";
import {IValidatedField} from "@/common/element/form/IValidatedField";

@Component({
    components: {
        StringInputField
    }
})
export default class PhoneNumberInputField extends Vue implements IValidatedField {
    @Model('input', { type: String })
    readonly fieldValue!: string

    @Prop({required: false, type: Boolean, default: false})
    readonly isDisabled!: boolean;

    @Prop({required: false, type: Boolean, default: false})
    readonly isRequired!: boolean;

    @Prop({required: false, type: String, default: ''})
    readonly name!: string;

    @Prop({required: false, type: String, default: ''})
    readonly id!: string;

    @Prop({required: false, type: String, default: ''})
    readonly successMessage!: string;

    @Prop({required: false, type: String, default: ''})
    readonly errorMessage!: string;

    @Prop({required: false, type: String, default: ''})
    readonly label!: string;

    @Prop({required: false, type: String, default: '+7'})
    readonly placeholder!: string;

    @Ref()
    readonly inputField!: IValidatedField

    readonly fieldMask: string = '+7 (###) ###-##-##';

    onChange(value: string) {
        this.$emit('input', value.leaveOnlyNumeric());
    }

    public validate() {
        return this.inputField.validate();
    }

    reset(): void {
        this.inputField.reset();
    }
}
