

import {Component} from "vue-property-decorator";
import MySiteVue from "@/main/components/MySiteVue";
import * as _ from 'lodash';
import Spinner from "@/common/element/Spinner.vue";
import SiteApiService from "@/main/service/SiteApiService";

@Component({
    components: {
        Spinner
    }
})
export default class OrderPaymentInstructionsPage extends MySiteVue {

    private orderNumber: number = 0;
    private buyButtonHtml: string = '';
    private isLoading: boolean = false;

    mounted() {
        this.orderNumber = parseInt(_.get(this.$route, 'params.orderNumber'));
        if (!this.orderNumber) {
            this.to404();
            return;
        }
        this.loadData();
    }

    async loadData() {
        this.isLoading = true;

        const response = await SiteApiService.orderGetBuyButton(this.orderNumber);
        if (response.isSuccess) {
            this.buyButtonHtml = response.data;
        } else {
            this.isLoading = false;
            this.to404();
        }
        this.isLoading = false;
    }

    to404() {
        this.$router.replace('/error404');
    }
}
