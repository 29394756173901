
import {Component} from "vue-property-decorator";
import MySiteVue from "@/main/components/MySiteVue";
import { MutationType } from "@/main/constants/MutationType";

@Component({
    components: {}
})
export default class WarrantyPage extends MySiteVue {
    created() {
        this.$store.commit(
            MutationType.BROWSER_META_SET,
            {
                seoTitle: 'Гарантия на товар - ElTehnika.com',
                seoDescription: 'Гарантия на товар - ElTehnika.com',
                seoKeywords: ''
            }
        );
    }
}
