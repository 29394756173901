

import {Component, Watch} from "vue-property-decorator";
import {Action, Getter, Mutation, State} from "vuex-class";
import {ActionType} from "@/main/constants/ActionType";
import BrandsListItem from "@/main/page/brand/BrandsListItem.vue";
import Spinner from "@/common/element/Spinner.vue";
import PageTitle from "@/main/components/common/PageTitle.vue";
import {MutationType} from "@/main/constants/MutationType";
import {BrandEntity} from "@/main/types/entity";
import MySiteVue from "@/main/components/MySiteVue";

@Component({
    components: {
        PageTitle,
        Spinner,
        BrandsListItem
    }
})
export default class BrandsListPage extends MySiteVue {

    @Action(ActionType.BRANDS_LIST_LOAD)
    readonly loadMore!: () => void;

    @Getter('getBrandsList')
    readonly brands!: BrandEntity[];

    @State(state => state.brands.isLoading)
    readonly isLoading!: boolean;

    @State(state => state.brands.isMore)
    readonly isMore!: boolean;

    @Mutation(MutationType.BRANDS_LIST_RESET)
    readonly resetList!: () => void;

    created() {
        this.loadMore();

        this.$store.commit(
            MutationType.BROWSER_META_SET,
            {
                seoTitle: 'Все бренды - ElTehnika.com',
                seoDescription: 'Поиск по брендам - ElTehnika.com',
                seoKeywords: ''
            }
        );
    }

    @Watch('$route')
    onRouteChanged() {
        this.resetList();
        this.loadMore();
    }
}
