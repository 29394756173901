import { render, staticRenderFns } from "./DeliveryInfoBlock.vue?vue&type=template&id=85991056&scoped=true&lang=pug&"
import script from "./DeliveryInfoBlock.vue?vue&type=script&lang=ts&"
export * from "./DeliveryInfoBlock.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "85991056",
  null
  
)

export default component.exports