

import {Component} from "vue-property-decorator";
import {CategoryEntity} from "@/main/types/entity";
import * as _ from 'lodash';
import SiteUtils from "@/main/service/SiteUtils";
import {State} from "vuex-class";
import MySiteVue from "../../MySiteVue";

@Component({
    components: {}
})
export default class CategoriesMenu extends MySiteVue {

    @State(state => state.categories.tree)
    readonly categoriesTree!: CategoryEntity[];

    private selectedImageUrl: string | null = null;
    private selectedCategory: CategoryEntity | null = null;

    getThumbnailUrl(category: CategoryEntity) {
        return _.get(
            _.first(category.images),
            'thumbnailUrl'
        )
    }

    getUrl(category: CategoryEntity) {
        return SiteUtils.getCategoryUrl(category);
    }

    onMouseOverLink(category: CategoryEntity) {
        this.selectedImageUrl = this.getThumbnailUrl(category) || null;
        this.selectedCategory = category;
    }

    onMouseLeaveLink() {
        this.selectedCategory = null;
        this.selectedImageUrl = null;
    }

    getClass(category: CategoryEntity) {
        return {
            'w-selected': this.selectedCategory && (
                category.id === this.selectedCategory.id
            ),
            'w-with-children': category.children.length > 0
        }
    }

    sortCategories(categories: CategoryEntity[]): CategoryEntity[] {
        return SiteUtils.sortCategories(categories);
    }
}
