
import {Component, Prop} from "vue-property-decorator";
import MySiteVue from "../../components/MySiteVue";
import {ProductEntity} from "../../types/entity";
import {ProductStockAvailabilityType} from "@/common/constants/ProductStockAvailabilityType";
import Nl2br from "@/common/element/Nl2br.vue";
import BuyButton from "@/main/components/product/BuyButton.vue";
import WishListButton from "@/main/components/product/WishListButton.vue";

@Component({
    components: {
        WishListButton,
        BuyButton,
        Nl2br
    }
})
export default class ProductCommonInfoBlock extends MySiteVue {

    @Prop({required: true, type: Object})
    readonly product!: ProductEntity;

    get
    stockAvailabilityType() {
        return this.product.stockAvailability;
    }

    get
    productStockAvailabilityName() {
        return ProductStockAvailabilityType.toString(this.stockAvailabilityType)
    }

    get
    productStockAvailabilityClass() {
        const type = this.stockAvailabilityType;
        if (type === ProductStockAvailabilityType.Yes) {
            return 'w-green';
        }
        if (type === ProductStockAvailabilityType.No) {
            return 'w-red';
        }
        return '';
    }
}
