import Vuex from 'vuex'
import Vue from 'vue'
import {RootState} from "@/main/types/store";
import app from "@/main/store/modules/app";
import basket from "@/main/store/modules/basket";
import brands from "@/main/store/modules/brands";
import categories from "@/main/store/modules/categories";
import deliveryService from "@/main/store/modules/deliveryService";
import filter from "@/main/store/modules/filter";
import orderForm from "@/main/store/modules/orderForm";
import product from "@/main/store/modules/product";
import productsList from "@/main/store/modules/productsList";
import user from "@/main/store/modules/user";
import browserMeta from "@/main/store/modules/browser-meta";

Vue.use(Vuex);

const store = new Vuex.Store<RootState>({
    state: {} as RootState,
    modules: {
        app,
        basket,
        brands,
        categories,
        deliveryService,
        filter,
        product,
        productsList,
        user,
        orderForm,
        browserMeta
    }
});

store.subscribe((mutation, _state: RootState) => {
    // console.info('Vuex mutation:', mutation.type, mutation.payload);
});

export default store;
