
import {MutationType} from "../../constants/MutationType";
import StringInputField from "@/common/element/form/StringInputField.vue";
import { Component, Ref, Watch } from "vue-property-decorator";
import {Getter, Mutation} from "vuex-class";
import MySiteVue from "@/main/components/MySiteVue";
import { DeliveryTypeEntity } from "@/main/types/entity";
import { OrderFormFormState } from "@/main/types/store";
import { IValidatedField } from "@/common/element/form/IValidatedField";
import SelectDeliveryService from "@/main/components/form/SelectDeliveryService.vue";

@Component({
    components: {
        SelectDeliveryService,
        StringInputField
    }
})
export default class DeliveryInfoBlock extends MySiteVue {

    @Mutation(MutationType.ORDER_UPDATE)
    readonly orderUpdate!: (data: object) => null;

    @Getter('getOrderFormData')
    readonly formData!: OrderFormFormState;

    @Ref()
    readonly deliveryTypeSelect!: IValidatedField;

    @Ref()
    readonly cityField!: IValidatedField;

    @Ref()
    readonly deliveryAddressField!: IValidatedField;

    private deliveryType: DeliveryTypeEntity | null = null;

    get
    isAddressRequired(): boolean {
        return this.deliveryType ? !!(this.deliveryType.isAddressRequired) : false;
    }

    get
    isCityRequired() {
        return this.deliveryType ? !!(this.deliveryType.isCityRequired) : false;
    }

    onChangeDeliveryType(deliveryType: DeliveryTypeEntity) {
        this.deliveryType = deliveryType;
        this.onChangeField('deliveryTypeId', deliveryType ? deliveryType.id : null);
    }

    onChangeField(name: string, value: string | number | null) {
        this.orderUpdate({
            [name]: value
        })
    }

    onContinue() {
        let deliveryTypeValid = this.deliveryTypeSelect.validate();
        let cityValid = this.cityField.validate();
        let deliveryAddressValid = this.deliveryAddressField.validate();
        if (!deliveryTypeValid || !cityValid || !deliveryAddressValid)
        {
            return;
        }
        this.$emit('onContinue');
    }
}
