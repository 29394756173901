
import {Component, Prop} from "vue-property-decorator";
import ShippingAndDeliveryBlock from "@/main/page/info/shippingAndDelivery/ShippingAndDeliveryBlock.vue";
import MySiteVue from "@/main/components/MySiteVue";
import { MutationType } from "@/main/constants/MutationType";

@Component({
    components: {
        ShippingAndDeliveryBlock
    }
})
export default class ShippingAndDeliveryPage extends MySiteVue {

    private deliveryImage = require('@/main/assets/images/delivery/delivery.png');
    private pickupImage = require('@/main/assets/images/delivery/pickup.svg');
    private cashPaymentImage = require('@/main/assets/images/payment/cash-payment.svg');
    private cashOnDeliveryImage = require('@/main/assets/images/payment/cash-on-delivery.svg');
    private invoiceImage = require('@/main/assets/images/payment/invoice.svg');

    created() {
        this.$store.commit(
            MutationType.BROWSER_META_SET,
            {
                seoTitle: 'Доставка и оплата - ElTehnika.com',
                seoDescription: 'Доставка и оплата - ElTehnika.com',
                seoKeywords: ''
            }
        );
    }
}
