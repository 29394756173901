

import {Component, Model, Prop, Watch} from "vue-property-decorator";
import MySiteVue from "@/main/components/MySiteVue";

@Component({
    components: {}
})
export default class Rating extends MySiteVue {

    @Model('change', { type: Number, required: false, default: 2 })
    readonly selectedValue!: number

    @Prop({required: false, type: String, default: ''})
    readonly label!: string;

    @Prop({required: false, type: Boolean, default: true})
    readonly isEditable!: string;

    private maxRating: number = 5;
    private selectedRating: number = 3;
    private innerSelectedValue: number = 0;

    created() {
        this.innerSelectedValue = this.selectedValue;
    }

    onSelect(item: number) {
        if (!this.isEditable) {
            return;
        }
        this.innerSelectedValue = item;
        this.$emit('change', item);
    }

    starIcon(rating: number) {
        if (rating <= this.innerSelectedValue) {
            return 'fa-solid fa-star'
        }
        return 'fa-regular fa-star'
    }

    reset() {
        this.innerSelectedValue = 3;
    }

    @Watch('selectedValue')
    selectedValueWatcher(value: number) {
        this.innerSelectedValue = value;
    }
}
