

import {Component, Prop} from "vue-property-decorator";
import MySiteVue from "../../components/MySiteVue";
import {ProductCommentEntity} from "@/main/types/entity";

@Component({
    components: {}
})
export default class CommentEntitySchema extends MySiteVue {
    @Prop({required: true, type: Object})
    readonly comment!: ProductCommentEntity;
}
