import {AppState, RootState} from "@/main/types/store";
import {Module} from "vuex";
import {MutationType} from "@/main/constants/MutationType";
import {ActionType} from "@/main/constants/ActionType";
import SiteApiService from "@/main/service/SiteApiService";
import {BasketEntity} from "@/main/types/entity";

const module: Module<AppState, RootState> = {
    state: {
        isInitialized: false
    } as AppState,
    mutations: {
        [MutationType.APP_IS_LOADING] (state, isLoading) {
            state.isLoading = isLoading;
        },
        [MutationType.APP_SET_IS_INITIALIZED] (state) {
            state.isInitialized = true;
        }
    },
    getters: {
        isAppInitialized(state) {
            return state.isInitialized;
        }
    },
    actions: {
        async [ActionType.APP_INIT] ({ commit, state }) {
            commit(MutationType.APP_IS_LOADING, true);

            SiteApiService.setAxiosSessionToken(localStorage['token'])

            const response = await SiteApiService.getInitData();
            if (response.isSuccess) {
                localStorage['token'] = response.data.token;
                SiteApiService.setAxiosSessionToken(response.data.token);

                commit(MutationType.FILTER_INIT, response.data);
                let basketData = new BasketEntity;
                if (response.data.basket) {
                    basketData = {  ...response.data.basket };
                }
                commit(MutationType.BASKET_UPDATE, basketData);
                commit(MutationType.USER_UPDATE, response.data.user);
                commit(MutationType.CATEGORIES_SET_LIST, response.data.categories);
                commit(MutationType.BRANDS_SET_TOP, response.data.topBrands);
                commit(MutationType.APP_IS_LOADING, false);
            }

            commit(MutationType.APP_IS_LOADING, false);
            commit(MutationType.APP_SET_IS_INITIALIZED);
        }
    }
};

export default module;
