import { render, staticRenderFns } from "./BrandsListItem.vue?vue&type=template&id=3e2341a1&scoped=true&lang=pug&"
import script from "./BrandsListItem.vue?vue&type=script&lang=ts&"
export * from "./BrandsListItem.vue?vue&type=script&lang=ts&"
import style0 from "./BrandsListItem.vue?vue&type=style&index=0&id=3e2341a1&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e2341a1",
  null
  
)

export default component.exports