import Utils from "@/common/service/Utils";

export enum ProductPageTab {
    MAIN= 'MAIN',
    FEATURES= 'FEATURES',
    DESCRIPTION= 'DESCRIPTION',
}

export namespace ProductPageTab {
    export function toString(type: ProductPageTab) {
        switch (type) {
            case ProductPageTab.MAIN:
                return 'Все о товаре';
            case ProductPageTab.FEATURES:
                return 'Характеристики';
            case ProductPageTab.DESCRIPTION:
                return 'Описание';
        }
    }


    export function getSelectList() {
        return [
            {name: ProductPageTab.toString(ProductPageTab.MAIN), value: ProductPageTab.MAIN},
            {name: ProductPageTab.toString(ProductPageTab.FEATURES), value: ProductPageTab.FEATURES},
            {name: ProductPageTab.toString(ProductPageTab.DESCRIPTION), value: ProductPageTab.DESCRIPTION},
        ]
    }
}
