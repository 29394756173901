import { FilterSelectedState, FilterState, RootState } from "@/main/types/store";
import {Module} from "vuex";
import {MutationType} from "@/main/constants/MutationType";
import {ActionType} from "@/main/constants/ActionType";
import SiteApiService from "@/main/service/SiteApiService";
import {FilterLoadDataMutationModel} from "@/main/types/mutationModel";

const defaultFilterValues = new FilterState().selected;

const module: Module<FilterState, RootState> = {
    state: new FilterState,
    mutations: {
        [MutationType.FILTER_INIT] (state, data) {
            state.brands = data.brands;
            state.features = data.features;
            state.isInitialized = true;
        },
        [MutationType.FILTER_SET_SORT_TYPE] (state, sortType) {
            state.sortType = sortType;
            console.log('FILTER_SET_SORT_TYPE', state)
        },
        [MutationType.FILTER_CHANGE] (state, data) {
            state.selected = { ...state.selected, ...data };
        },
        [MutationType.FILTER_RESET] (state) {
            state.selected = { ...state.selected, ...defaultFilterValues };
        },
        [MutationType.FILTER_PARTIAL_RESET] (state) {
            state.selected = {
                ...state.selected,
                searchString: null,
                minPrice: null,
                maxPrice: null,
                brandIds: [],
                optionIds: []
            };
        },
        [MutationType.FILTER_RESET] (state) {
            state.selected = { ...state.selected, ...defaultFilterValues };
        },
        [MutationType.FILTER_IS_LOADING] (state, isLoading) {
            state.isLoading = isLoading;
        }
    },
    getters: {
        productsFilter: (state): FilterSelectedState => {
            return state.selected
        },
        filterState: (state): FilterState => {
            return state
        }
    },
    actions: {
        async [ActionType.FILTER_LOAD_DATA] ({ commit, dispatch, state }, data: FilterLoadDataMutationModel) {
            commit(MutationType.FILTER_IS_LOADING, true);

            const response = await SiteApiService.filterGetData(
                data.categoryShortName,
                data.brandShortName,
                data.searchString
            );
            if (response.isSuccess) {
                if (response.data.categoryId) {
                    commit(MutationType.FILTER_CHANGE, { categoryId: response.data.categoryId });
                }
                if (response.data.brand) {
                    commit(MutationType.FILTER_CHANGE, { brandId: response.data.brand.id });
                    commit(MutationType.PRODUCTS_LIST_SET_VIEW_DATA, { brand: response.data.brand });
                }
                if (response.data.searchString) {
                    commit(MutationType.FILTER_CHANGE, { searchString: response.data.searchString });
                }

                commit(MutationType.FILTER_INIT, response.data);
                commit(MutationType.FILTER_IS_LOADING, false);
                dispatch(ActionType.PRODUCTS_LIST_LOAD);
            } else {
                commit(MutationType.FILTER_IS_LOADING, false);
            }
        },
    }
};

export default module;
