import {Component} from "vue-property-decorator";
import Vue from "vue";
import {Getter, Mutation, State} from "vuex-class";
import {CategoryEntity} from "@/main/types/entity";
import * as _ from 'lodash';
import {MutationType} from "@/main/constants/MutationType";

@Component({})
export default class MySiteVue extends Vue {

    @Getter('getCategoryById')
    readonly getCategoryById!: (id: number | string) => CategoryEntity;

    @Getter('isUserLoggedIn')
    readonly isLoggedIn!: boolean;

    @Getter('isAdmin')
    readonly isAdminUser!: boolean;

    @Mutation(MutationType.USER_SHOW_REGISTRATION_MODAL)
    showLoginModal() { }

    @Mutation(MutationType.USER_HIDE_REGISTRATION_MODAL)
    hideLoginModal() { }
}
