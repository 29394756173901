import {BrandsState, CategoriesState, RootState} from "@/main/types/store";
import {Module} from "vuex";
import {MutationType} from "@/main/constants/MutationType";
import _ from "lodash";
import {CategoryEntity} from "@/main/types/entity";

const module: Module<CategoriesState, RootState> = {
    state: new CategoriesState,
    mutations: {
        [MutationType.CATEGORIES_SET_LIST] (state, list) {
            state.list = list;

            function buildCategoriesTree(categories: CategoryEntity[], parentId: number | null, level: number) {
                const branch = [];

                for(const index in categories) {
                    const element = categories[index];
                    if (element.parentId === parentId) {
                        const children = buildCategoriesTree(categories, element.id, ++level);
                        if (children) {
                            element.children = children;
                        }
                        branch.push(element);
                    }
                }
                return branch;
            }

            function buildCategoryParentPath(categoriesTree: CategoryEntity[], parentPath: number[]) {
                for(const index in categoriesTree) {
                    const element = categoriesTree[index];
                    element.parentIdsPath = [...parentPath];
                    element.parentIdsPath.push(element.id);
                    buildCategoryParentPath(element.children, element.parentIdsPath);
                }
            }

            // Add 'children' and 'parentIdsPath' to 'state.list' too
            state.tree = buildCategoriesTree(state.list, null, 1);
            buildCategoryParentPath(state.tree, []);
        }
    },
    getters: {
        getCategoryById: state => (id: number) => {
            return _.find(state.list, category => {
                return category.id === id || category.url === `${id}`;
            });
        },
        getRootCategories: state => {
            return _.filter(state.list, category => {
                return !(category.parentId);
            });
        }
    },
    actions: {}
};

export default module;
