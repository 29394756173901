import {DeliveryServicesState, RootState} from "@/main/types/store";
import {Module} from "vuex";
import {MutationType} from "@/main/constants/MutationType";
import _ from "lodash";
import {ActionType} from "@/main/constants/ActionType";
import SiteApiService from "@/main/service/SiteApiService";

const module: Module<DeliveryServicesState, RootState> = {
    state: new DeliveryServicesState,
    mutations: {
        [MutationType.DELIVERY_SERVICES_UPDATE] (state, data) {
            state.list = data;
            state.isLoading = false;
        },
        [MutationType.DELIVERY_SERVICES_IS_LOADING] (state, isLoading) {
            state.isLoading = isLoading;
        },
    },
    getters: {
        getDeliveryServices: state => {
            return state.list
        }
    },
    actions: {
        [ActionType.DELIVERY_SERVICES_LOAD]: _.throttle(async ({ commit, state, getters }) => {
            commit(MutationType.DELIVERY_SERVICES_IS_LOADING, true);
            const response = await SiteApiService.deliveryServicesGetList();
            if (response.isSuccess) {
                commit(MutationType.DELIVERY_SERVICES_UPDATE, response.data.items);
            } else {
                commit(MutationType.DELIVERY_SERVICES_UPDATE, []);
            }
        }, 500),
    }
};

export default module;
