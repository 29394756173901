import { FilterState, ProductsListState, RootState } from "@/main/types/store";
import { Module } from "vuex";
import { MutationType } from "@/main/constants/MutationType";
import { ActionType } from "@/main/constants/ActionType";
import SiteApiService from "@/main/service/SiteApiService";
import _ from "lodash";
import Vue from "vue";
import Utils from "@/common/service/Utils";
import { ListResponseModel } from "../../../common/type/apiRequest";
import { ProductEntity } from "../../types/entity";

const module: Module<ProductsListState, RootState> = {
    state: new ProductsListState,
    mutations: {
        [MutationType.PRODUCTS_LIST_UPDATE](state, data: ListResponseModel<ProductEntity>) {
            state.isLoading = false;
            state.isMore = data.pageSize === data.items.length;
            state.isWasFirstLoad = true;
            state.page++;
            _.forEach(data.items, (item) => {
                Utils.mergeArrayById(state.list, item, 'id')
            });
        },
        [MutationType.PRODUCTS_LIST_SET_LOADING](state, isLoading) {
            state.isLoading = isLoading;
        },
        [MutationType.PRODUCTS_LIST_SET_GRID_TYPE](state, gridType) {
            state.gridType = gridType;
        },
        [MutationType.PRODUCTS_LIST_SET_VIEW_DATA](state, data) {
            state.viewData = {...state.viewData, ...data};
        },
        [MutationType.PRODUCTS_LIST_RESET](state) {
            state.page = 1;
            state.isWasFirstLoad = false;
            state.viewData.brand = null;
            state.list = [];
            state.categoriesShowcaseList = [];
        },
        [MutationType.PRODUCTS_LIST_UPDATE_PRODUCT](state, product) {
            for (const index in state.list) {

                const existProduct = state.list[index];
                if (existProduct.id === product.id) {
                    Vue.set(state.list, index, {...existProduct, ...product});
                }
            }
        },
        [MutationType.PRODUCTS_LIST_UPDATE_CATEGORY_SHOWCASE](state, data) {
            state.categoriesShowcaseList = [];
            _.forEach(data.items, (item) => {
                Utils.mergeArrayById(state.categoriesShowcaseList, item, 'id')
            });
        },
    },
    getters: {
        productsList(state) {
            return state.list;
        },
        categoriesShowcaseList(state) {
            return state.categoriesShowcaseList;
        },
    },
    actions: {
        async [ActionType.PRODUCTS_LIST_RELOAD]({commit, state, getters, dispatch}) {
            commit(MutationType.PRODUCTS_LIST_RESET);
            dispatch(ActionType.PRODUCTS_LIST_LOAD);
        },
        [ActionType.PRODUCTS_LIST_LOAD]: _.throttle(async ({commit, state, getters, dispatch}) => {
            commit(MutationType.PRODUCTS_LIST_SET_LOADING, true);

            const filterState: FilterState = getters.filterState;
            const response = await SiteApiService.productsGetList(
                {
                    page: state.page,
                    categoryId: filterState.selected.categoryId,
                    brandId: filterState.selected.brandId,
                    priceFrom: filterState.selected.minPrice,
                    priceTo: filterState.selected.maxPrice,
                    brandIds: filterState.selected.brandIds,
                    optionIds: filterState.selected.optionIds,
                    sort: filterState.sortType,
                    searchString: filterState.selected.searchString,
                    brandIdOrUrl: filterState.selected.brandId,
                }
            );
            if (response.isSuccess) {
                if (state.page === 1) {
                    dispatch(ActionType.PRODUCTS_LIST_LOAD_CATEGORIES_SHOWCASE);
                }
                commit(MutationType.PRODUCTS_LIST_UPDATE, response.data);
            } else {
                commit(MutationType.PRODUCTS_LIST_SET_LOADING, false)
            }
        }, 500),
        [ActionType.PRODUCTS_LIST_LOAD_SHOWCASE]: _.throttle(async ({commit, state, getters}) => {
            commit(MutationType.PRODUCTS_LIST_SET_LOADING, true);
            const response = await SiteApiService.productsGetShowcaseList();
            if (response.isSuccess) {
                commit(MutationType.PRODUCTS_LIST_UPDATE, response.data);
            } else {
                commit(MutationType.PRODUCTS_LIST_SET_LOADING, false)
            }
        }),
        [ActionType.PRODUCTS_LIST_LOAD_CATEGORIES_SHOWCASE]: _.throttle(async ({commit, state, getters}) => {
            commit(MutationType.PRODUCTS_LIST_SET_LOADING, true);
            commit(MutationType.PRODUCTS_LIST_UPDATE_CATEGORY_SHOWCASE, []);
            const filter = getters.productsFilter;
            if (filter.categoryId) {
                const response = await SiteApiService.productsGetCategoriesShowcaseList(filter.categoryId);
                if (response.isSuccess) {
                    commit(MutationType.PRODUCTS_LIST_UPDATE_CATEGORY_SHOWCASE, response.data);
                } else {
                    commit(MutationType.PRODUCTS_LIST_SET_LOADING, false)
                }
            } else {
                commit(MutationType.PRODUCTS_LIST_SET_LOADING, false)
            }
        }),
        [ActionType.PRODUCTS_WISHLIST_SET]: _.throttle(async ({commit, state, getters}, data) => {
            const isWishlisted = _.get(data, 'isWishlisted', false);
            const productId = _.get(data, 'productId');
            commit(MutationType.PRODUCTS_LIST_UPDATE_PRODUCT, {
                id: productId,
                isWishlisted
            });
            await SiteApiService.productsWishListSet(isWishlisted, productId);
        }, 500)
    }
};

export default module;
