import {
    BasketEntity,
    BrandEntity,
    CategoryEntity,
    DeliveryServiceEntity,
    FeatureEntity,
    ProductEntity, UserEntity
} from "@/main/types/entity";
import {ProductSortType} from "@/main/constants/ProductSortType";
import {OrderPaymentMethod} from "@/common/constants/OrderPaymentMethod";
import {ProductGridType} from "@/main/constants/ProductGridType";

export interface RootState {}

export interface AppState {
    isLoading: boolean
    isInitialized: boolean
}

export class BasketState {
    public amount: number = 0;
    public productsQuantity: number = 0;
    public basketItems: BasketEntity[] = [];
    public isLoading: boolean = false;
    public isShowBasketModal: boolean = false;
}

export class BrandsState {
    public topBrands: BrandEntity[] = [];
    public list: BrandEntity[] = [];
    public isMore: boolean = true;
    public page: number = 1;
    public isLoading: boolean = false;
}

export class CategoriesState {
    public list: CategoryEntity[] = [];
    public tree: CategoryEntity[] = [];
}

export class DeliveryServicesState {
    public list: DeliveryServiceEntity[] = [];
    public isLoading: boolean = false;
}

export class FilterState {
    public selected: FilterSelectedState = new FilterSelectedState;
    public sortType: ProductSortType = ProductSortType.POPULAR;
    public brands: BrandEntity[] = [];
    public features: FeatureEntity[] = [];
    public isLoading: boolean = false;
    public isInitialized: boolean = false;
}

export class FilterSelectedState {
    public categoryId: number | null = null;
    public brandId: number | null = null;
    public searchString: string | null = null;
    public minPrice: number | null = null;
    public maxPrice: number | null = null;
    public brandIds: number[] = [];
    public optionIds: number[] = [];
}

export class OrderFormState {
    public form: OrderFormFormState = new OrderFormFormState;
    public isLoading: boolean = false;
}

export class OrderFormFormState {
    public email: string | null = null;
    public phoneNumber: string = '';
    public firstName: string = '';
    public lastName: string = '';
    public additionalInfo: string = '';
    public deliveryTypeId: number | null = null;
    public city: string = '';
    public deliveryAddress: string = '';
    public paymentMethod: OrderPaymentMethod = OrderPaymentMethod.CashPayment;
}

export class ProductState {
    public data: ProductEntity | null = null;
    public isLoading: boolean = false;
    public isLoaded: boolean = false;
}

export class ProductsListState {
    public list: ProductEntity[] = [];
    public categoriesShowcaseList: ProductEntity[] = [];
    public isMore: boolean = true;
    public page: number = 1;
    public isLoading: boolean = false;
    public isWasFirstLoad: boolean = false;
    public gridType: ProductGridType = ProductGridType.LIST;
    public viewData: { brand: BrandEntity | null } = { brand: null };
}

export class UserState {
    public data: UserEntity | null = null;
    public isLoggedIn: boolean = false;
    public isShowLoginModal: boolean = false;
    public isShowRegistrationModal: boolean = false;
}

export class BrowserMetaState {
    public seoTitle: string = '';
    public seoDescription: string = '';
    public seoKeywords: string = '';
}
