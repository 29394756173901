

import {Component, Watch} from "vue-property-decorator";
import PageTitle from "@/main/components/common/PageTitle.vue";
import CategoryList from "@/main/components/category/CategoryList.vue";
import {CategoryEntity} from "@/main/types/entity";
import * as _ from 'lodash';
import MySiteVue from "@/main/components/MySiteVue";
import { MutationType } from "@/main/constants/MutationType";

@Component({
    components: {
        PageTitle,
        CategoryList
    }
})
export default class CategoryChildrenPage extends MySiteVue {

    private parentCategoryId: string | null = null;

    created() {
        this.setCategoryId();
    }

    getUrl(category: CategoryEntity) {
        return category.children.length > 0
            ? '/category/' + category.url
            : '/category/products/' + category.url
    }

    setCategoryId() {
        this.parentCategoryId = _.get(this.$route, 'params.shortName');
    }

    get
    parentCategory(): CategoryEntity | null {
        if (this.parentCategoryId) {
            return this.getCategoryById(this.parentCategoryId);
        }
        return null;
    }

    @Watch('$route')
    onRouteChanged() {
        this.setCategoryId();
    }

    @Watch('parentCategory')
    parentCategoryChanged(category: CategoryEntity | null) {
        this.$store.commit(
            MutationType.BROWSER_META_SET,
            {
                seoTitle: `Все продукты в категории ${category?.name} - ElTehnika.com`,
                seoDescription: `Все продукты в категории ${category?.name} - ElTehnika.com`,
                seoKeywords: ''
            }
        );
    }
}
