
import {Component, Watch} from "vue-property-decorator";
import Utils from "@/common/service/Utils";
import SiteApiService from "@/main/service/SiteApiService";
import {Getter, Mutation} from "vuex-class";
import {OrderFormFormState} from "@/main/types/store";
import {MutationType} from "@/main/constants/MutationType";
import {OrderPaymentMethod} from "@/common/constants/OrderPaymentMethod";
import PaymentMethodBlock from "@/main/components/order/PaymentMethodBlock.vue";
import UserInfoBlock from "@/main/components/order/UserInfoBlock.vue";
import DeliveryInfoBlock from "@/main/components/order/DeliveryInfoBlock.vue";
import CustomModal from "@/common/modal/CustomModal.vue";
import PhoneNumberInputField from "@/common/element/form/PhoneNumberInputField.vue";
import StringInputField from "@/common/element/form/StringInputField.vue";
import MySiteVue from "@/main/components/MySiteVue";

enum FormStateType {
    USER_INFO,
    DELIVERY_INFO,
    PAYMENT_INFO
}

@Component({
    components: {
        PaymentMethodBlock,
        UserInfoBlock,
        DeliveryInfoBlock,
        CustomModal,
        PhoneNumberInputField,
        StringInputField
    }
})
export default class OrderForm extends MySiteVue {

    @Getter('getOrderFormData')
    readonly formData!: OrderFormFormState;

    private formState: FormStateType = FormStateType.USER_INFO;

    setState(state: FormStateType) {
        this.formState = state;
    }

    @Mutation(MutationType.BASKET_RESET)
    readonly basketReset!: () => void;

    @Mutation(MutationType.ORDER_RESET)
    readonly orderReset!: () => void;

    async orderAdd() {
        let formData = Utils.fromReactiveObject(this.formData);
        const paymentMethod = this.formData.paymentMethod;
        const response = await SiteApiService.orderAdd({
            ...formData,
            phoneNumber: formData.phoneNumber
        });
        if (response.isSuccess) {
            this.basketReset();
            this.orderReset();

            if (paymentMethod === OrderPaymentMethod.VisaMasterCard) {
                await this.$router.push('/order/payment_instructions/' + response.data.orderNumber);
                return;
            }
            await this.$router.push('/order/sent/' + response.data.orderNumber);
        } else {
            console.error(response.message)
        }
    }

    get
    menu1ActiveClass() {
        return this.formState >= FormStateType.USER_INFO ? 'active' : '';
    }

    get
    menu2ActiveClass() {
        return this.formState >= FormStateType.DELIVERY_INFO ? 'active' : '';
    }

    get
    menu3ActiveClass() {
        return this.formState >= FormStateType.PAYMENT_INFO ? 'active' : '';
    }

    get
    FormStateType() {
        return FormStateType;
    }

    @Watch('$store.getters.isBasketEmpty')
    isBasketEmptyWatcher(value: boolean) {
        if (value) {
            this.$router.replace('/error404');
        }
    }
}
