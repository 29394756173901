import Utils from "../service/Utils";

export enum OrderPaymentMethod {
    CashPayment = 'CashPayment',
    ToCreditCard = 'ToCreditCard',
    VisaMasterCard = 'VisaMasterCard',
}

export namespace OrderPaymentMethod {
    export function toString(status: OrderPaymentMethod): string {
        switch (status) {
            case OrderPaymentMethod.CashPayment:
                return 'Наличными';
            case OrderPaymentMethod.ToCreditCard:
                return 'Кредитной картой';
            case OrderPaymentMethod.VisaMasterCard:
                return 'На карту';
        }
    }

    export function toSelectList(): { id: string, name: string }[] {
        return [
            { id: OrderPaymentMethod.CashPayment.toString(), name: toString(OrderPaymentMethod.CashPayment) },
            { id: OrderPaymentMethod.ToCreditCard.toString(), name: toString(OrderPaymentMethod.ToCreditCard) },
            { id: OrderPaymentMethod.VisaMasterCard.toString(), name: toString(OrderPaymentMethod.VisaMasterCard) }
        ];
    }
}
