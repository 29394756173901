

import {Component, Prop} from "vue-property-decorator";
import {ActionType} from "@/main/constants/ActionType";
import {MutationType} from "@/main/constants/MutationType";
import {Action, Getter, Mutation, State} from "vuex-class";
import MySiteVue from "@/main/components/MySiteVue";

@Component({
    components: {}
})
export default class BuyButton extends MySiteVue {

    @Prop({required: true, type: Number})
    readonly productId!: number;

    @Action(ActionType.BASKET_ADD_REMOVE_PRODUCT)
    readonly addRemoveProduct!: (data: object) => void;

    @Mutation(MutationType.BASKET_SHOW_MODAL)
    readonly showBasketModal!: () => void;

    @Getter('isProductInBasket')
    readonly isProductInBasket!: (productId: number) => boolean;

    @State(state => state.basket.isLoading)
    readonly isLoading!: boolean;

    setProduct(quantity: number) {
        this.addRemoveProduct({
            productId: this.productId,
            quantity
        });
        if (quantity > 0) {
            this.showBasketModal();
        }
    }

    get
    isExistInBasket() {
        return this.isProductInBasket(this.productId);
    }
}
