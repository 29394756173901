

import {Component, Watch} from "vue-property-decorator";
import {CategoryEntity, ProductEntity} from "@/main/types/entity";
import * as _ from 'lodash';
import SiteApiService from "@/main/service/SiteApiService";
import SiteUtils from "@/main/service/SiteUtils";
import MySiteVue from "@/main/components/MySiteVue";
import {Debounce} from "@/common/decorators/DebounceDecorator";

@Component({
    components: {}
})
export default class HeaderSearchField extends MySiteVue {

    private isShowResults: boolean = false;
    private searchString: string = '';
    private isLoading: boolean = false;
    private results: {
        products: ProductEntity[],
        categories: CategoryEntity[]
    } = { products: [], categories: [] };

    mounted() {
        document.addEventListener('click', this.onClickOutside);
    }

    beforeDestroy() {
        document.removeEventListener('click', this.onClickOutside);
    }

    onClickOutside() {
        this.isShowResults = false;
    }

    onFocus() {
        if (this.searchString.length > 2) {
            this.isShowResults = true;
        }
    }

    onSubmit() {
        if (_.isEmpty(this.searchString)) {
            return;
        }
        this.isShowResults = false;
        this.$router.push('/search/' + encodeURIComponent(this.searchString));
    }

    @Debounce(200)
    async search(searchString: string) {
        if (searchString.length <= 2) {
            this.isShowResults = false;
            this.resetResults();
            return;
        }
        this.isShowResults = true;
        this.isLoading = true;
        const response = await SiteApiService.filterQuickSearch(searchString);
        if (response.isSuccess) {
            this.showSearchResults(response.data.products, response.data.categoryIds);
        }
        this.isLoading = false;
    }

    showSearchResults(products: ProductEntity[], categoryIds: number[]) {
        this.results.categories = _.map(
            _.slice(categoryIds, 0, 5),
            categoryId => this.getCategoryBy(categoryId)
        );
        this.results.products = _.slice(products, 0, 5);

        if (this.isEmptyResult) {
            this.resetResults();
            return;
        }
    }

    resetResults() {
        this.results.categories = [];
        this.results.products = [];
    }

    getCategoryBy(id: number) {
        return this.$store.getters.getCategoryById(id);
    }

    getCategoryUrl(category: CategoryEntity) {
        return SiteUtils.getCategoryUrl(category);
    }

    get
    isEmptyResult() {
        return this.results.products.length === 0 && this.results.categories.length === 0
    }

    get
    isShowEmptyResult() {
        return this.isEmptyResult && this.searchString.length > 2;
    }

    @Watch('searchString')
    searchStringWatcher(value: string) {
        this.search(value);
    }
}
