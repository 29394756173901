
import {Component} from "vue-property-decorator";
import {Mutation, State} from "vuex-class";
import {MutationType} from "@/main/constants/MutationType";
import MySiteVue from "@/main/components/MySiteVue";

@Component({
    components: {}
})
export default class BasketInfoBlock extends MySiteVue {
    @State(state => state.basket.amount)
    readonly amount!: number;

    @State(state => state.basket.productsQuantity)
    readonly productsQuantity!: number;

    @State(state => state.basket.isLoading)
    readonly isBasketDataLoading!: boolean;

    @Mutation(MutationType.BASKET_SHOW_MODAL)
    readonly openBasketModal!: () => null;

    get
    isShow() {
        return this.productsQuantity > 0;
    }
}
