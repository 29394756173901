
import {Component, Watch} from "vue-property-decorator";
import MySiteVue from "../../components/MySiteVue";
import {Action, Getter, State} from "vuex-class";
import {ProductEntity} from "../../types/entity";
import ProductEntitySchema from "@/main/page/product/ProductEntitySchema.vue";
import ProductCommentsBlock from "@/main/page/product/ProductCommentsBlock.vue";
import ProductFeaturesBlock from "@/main/page/product/ProductFeaturesBlock.vue";
import ProductDescriptionBlock from "@/main/page/product/ProductDescriptionBlock.vue";
import ProductCommonInfoBlock from "@/main/page/product/ProductCommonInfoBlock.vue";
import ProductImagesBlock from "@/main/page/product/ProductImagesBlock.vue";
import Spinner from "@/common/element/Spinner.vue";
import {ActionType} from "@/main/constants/ActionType";
import {ProductPageTab} from "@/main/constants/ProductPageTab";
import * as _ from 'lodash';

@Component({
    components: {
        ProductEntitySchema,
        ProductCommentsBlock,
        ProductFeaturesBlock,
        ProductDescriptionBlock,
        ProductCommonInfoBlock,
        ProductImagesBlock,
        Spinner
    }
})
export default class ProductPage extends MySiteVue {

    @Action(ActionType.PRODUCT_LOAD)
    readonly productLoad!: (productId: string) => void

    @State(state => state.product.isLoading)
    readonly isLoading!: boolean;

    @State(state => state.product.isLoaded)
    readonly isLoaded!: boolean;

    @Getter('getCurrentProduct')
    readonly product!: ProductEntity;

    private productShortName: string = '';
    private selectedTab: ProductPageTab = ProductPageTab.MAIN;

    created() {
        this.updatePage();
    }

    updatePage() {
        this.productShortName = _.get(this.$route, 'params.productShortName');
        if (!this.productShortName) {
            this.$router.replace('/error404');
        }
        this.productLoad(this.productShortName);
    }

    onClickTab(id: ProductPageTab) {
        this.selectedTab = id;
    }

    isSelectedTab(id: ProductPageTab) {
        return this.selectedTab === id;
    }

    get
    isProductLoading() {
        return this.isLoading || !this.isLoaded;
    }

    get
    tabs() {
        return ProductPageTab.getSelectList();
    }

    get
    ProductPageTab() {
        return ProductPageTab;
    }

    @Watch('$route.path')
    routePathWatcher(path: string) {
        if (path.startsWith('/product/')) {
            this.updatePage();
        }
    }
}
