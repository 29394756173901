
import {Component, Prop} from "vue-property-decorator";
import {CategoryEntity} from "@/main/types/entity";
import SiteUtils from "@/main/service/SiteUtils";
import {Getter} from "vuex-class";
import MySiteVue from "../MySiteVue";

@Component({
    components: {}
})
export default class CategoryListItem extends MySiteVue {

    @Prop({required: false, type: Object, default: ''})
    readonly category!: CategoryEntity;

    getUrl(category: CategoryEntity) {
        return SiteUtils.getCategoryUrl(category);
    }
}
