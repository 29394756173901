import {OrderStatus} from "@/common/constants/OrderStatus";
import {ProductStockAvailabilityType} from "@/common/constants/ProductStockAvailabilityType";

export class BasketEntity {
    public id: number = 0;
    public productsQuantity: number = 0;
    public amount: number = 0;
    public order: OrderEntity[] = [];
    public basketItems: BasketItemEntity[] = []
}

export class BasketItemEntity {
    constructor(
        public id: number,
        public productId: number,
        public basketId: number,
        public productQuantity: number,
        public productPrice: number,
        public amount: number,

        public product: ProductEntity | null = null
    ) {
    }
}

export class BrandEntity {
    constructor(
        public id: number,
        public name: string,
        public url: string,
        public title: string,
        public seoTitle: string,
        public seoKeywords: string,
        public seoDescription: string,
        public description: string,
        public activeProductsCount: number = 0,

        public images: CommonImageEntity[] = [],
        public categories: CommonImageEntity[] = []
    ) {
    }
}

export class CommonImageEntity {
    constructor(
        public id: number,
        public thumbnailUrl: string,
        public url: string,
    ) {}
}

export class CategoryEntity {
    constructor(
        public id: number,
        public parentId: number,
        public url: string,
        public seoTitle: string,
        public seoDescription: string,
        public seoKeywords: string,
        public seoKeywordsAdditional: string,
        public name: string,
        public level: number,
        public description: number,
        public position: number,
        public titlePage: string,

        public images: CategoryImageEntity[] = [],

        public parentIdsPath: number[] = [],
        public children: CategoryEntity[] = [],
        public parent?: CategoryEntity | null,
    ) {}
}

export class DeliveryServiceEntity {
    constructor(
        public id: number,
        public name: string,

        public deliveryTypes: DeliveryTypeEntity[] = [],
    ) {}

    static getEmpty() {
        return new this(0, '');
    }
}

export class DeliveryTypeEntity {
    constructor(
        public id: number,
        public name: string,
        public deliveryServiceId: number,
        public isAddressRequired: boolean,
        public isCityRequired: boolean,
    ) {
    }
}

export class FeatureEntity {
    constructor(
        public id: number,
        public categoryId: number,
        public featureCategoryId: number,
        public name: string,
        public position: string,

        public featureCategory: FeatureCategoryEntity,
        public options: FeatureOptionEntity[] = [],
    ) {
    }
}

export class FeatureOptionEntity {
    constructor(
        public id: number,
        public featureId: number,
        public name: string,

        public feature: FeatureEntity
    ) {
    }
}

export class OrderEntity {
    constructor(
        public id: number,
        public basketId: number,
        public userId: number,
        public orderNumber: number,
        public firstName: string,
        public lastName: string,
        public city: string,
        public region: string,
        public address: string,
        public additionalInfo: string,
        public deliveryAddress: string,
        public deliveryTypeId: number,
        public status: OrderStatus,

        public statusLabel: string,
        public basket: BasketEntity,
        public statusColors: OrderStatusColorsEntity
    ) {
    }
}

export class OrderStatusColorsEntity {
    constructor(
        public text: string,
        public background: string,
    ) {
    }
}

export class ProductCommentEntity {
    public id: number = 0;
    public parentId: number = 0;
    public productId: number = 0;
    public rating: number = 3;
    public comment: string = '';
    public commentDignity: string = '';
    public commentShortcomings: string = '';
    public userName: string = '';
    public createdAt: number = 0;

    public answers: ProductCommentEntity[] = []
}

export class ProductEntity {
    constructor(
        public id: number,
        public shortName: string,
        public brandId: number,
        public categoryId: number,
        public title: string,
        public seoTitle: string,
        public oldPrice: number,
        public price: number,
        public stockAvailability: ProductStockAvailabilityType,
        public productModel: string,
        public articul: string,
        public seoKeywords: string,
        public seoDescription: string,
        public text: number,
        public textAdditional: number,
        public order: number,
        public rating: number,

        public brand: BrandEntity,
        public images: CommonImageEntity[] = [],
        public categories: CategoryEntity[] = [],
        public options: FeatureOptionEntity[] = [],
        public features: FeatureEntity[] = [],
        public comments: ProductCommentEntity[] = [],
    ) {
    }
}

export class CategoryImageEntity extends CommonImageEntity {
    constructor(
        id: number,
        thumbnailUrl: string,
        url: string,
        public categoryId: number,
    ) {
        super(id, thumbnailUrl, url);
    }
}

export class FeatureCategoryEntity {
    constructor(
        public id: number = 0,
        public name: string = '',
    ) {
    }
}

export class UserEntity {
    constructor(
        public id: number = 0,
        public username: string = '',
        public firstName: string = '',
        public lastName: string = '',
    ) {
    }
}
