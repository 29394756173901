import {ProductSortType} from "@/main/constants/ProductSortType";

export class FindProductsRequest {
    public page: number = 1;
    public categoryId: number | null = null;
    public brandId: number | null = null;
    public priceFrom: number | null = null;
    public priceTo: number | null = null;
    public brandIdOrUrl: string | number | null = null;
    public brandIds: number[] = [];
    public optionIds: number[] = [];
    public sort: ProductSortType = ProductSortType.POPULAR;
    public searchString: string | null = '';
}

export class AddCommentRequestModel {
    public productId: number = 0;
    public rating: number = 3;
    public userName: string = '';
    public comment: string = '';
    public commentDignity: string = '';
    public commentShortcomings: string = '';
    public isSendNotification: boolean = true;
}

export class SendFeedbackRequestModel {
    public name: string = '';
    public email: string = '';
    public subject: string = '';
    public message: string = '';
}

export class GetCategoriesShowcaseRequest {
    public page: number = 1;
    public categoryId: number | null = null;
}
