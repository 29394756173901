import {ApiResponseModel, ApiRequestStatus, ApiResponseModel2, ListResponseModel} from "../type/apiRequest";
import * as _ from 'lodash';

const axios = require('axios').default;

export class ApiService {

    public setAxiosSessionToken(token: string | null) {
        axios.defaults.headers.common['Authorization'] = token ? `Bearer ${token}` : null;
        axios.defaults.baseURL = 'https://api.eltehnika.com';
    }

    public async prepareResponse<Type>(response: ApiResponseModel) : Promise<Type> {
        return new Promise<Type>((resolve, reject) => {
            if (response!.isSuccess) {
                resolve(response!.data as Type);
            } else {
                reject(response?.message);
            }
        });
    }

    public async postRequest(url: string, request?: any) : Promise<ApiResponseModel> {
        const { data } = await axios.post(url, request);
        return this.parseApiRequest(new ApiResponseModel(data));
    }

    public async getRequest(url: string) : Promise<ApiResponseModel> {
        const { data } = await axios.get(url);
        return this.parseApiRequest(new ApiResponseModel(data));
    }

    public getRequest2<Type>(url: string) : Promise<ApiResponseModel2<Type>> {
        return axios.get(url)
            .then((exiosResponse: any) => {
                return new ApiResponseModel2<Type>(exiosResponse);
            })
            .catch((exiosResponse: string) => {
                return new ApiResponseModel2<Type>(exiosResponse);
            });
    }

    public postRequest2<Type>(url: string, request?: any) : Promise<ApiResponseModel2<Type>> {
        return axios.post(url, request)
            .then((exiosResponse: any) => {
                return new ApiResponseModel2<Type>(exiosResponse);
            })
            .catch((exiosResponse: string) => {
                return new ApiResponseModel2<Type>(exiosResponse);
            });
    }


    public async uploadFile<Type>(url: string, files: File[], requestData?: object) {

        const formData = new FormData();
        _.forEach(files, (file: File, index) => {
            formData.append(`files[]`, file, file.name);
        });

        _.forEach(
            _.keys(requestData || {}),
            (dataKey: any) => {
                formData.append(dataKey, _.get(requestData, dataKey));
            }
        );

        return await axios.post(url, formData, {

        }).then((exiosResponse: any) => {
            return new ApiResponseModel2<Type>(exiosResponse);
        })
        .catch((exiosResponse: string) => {
            return new ApiResponseModel2<Type>(exiosResponse);
        });
    }

    private parseApiRequest(response: ApiResponseModel) : Promise<ApiResponseModel> {
        return new Promise<ApiResponseModel>((resolve, reject) => {
            if (response.status === 200) {
                resolve(response);
            } else {
                reject(response);
            }
        });
    }
}

export default new ApiService();
