import {BrandsState, RootState} from "@/main/types/store";
import {Module} from "vuex";
import {MutationType} from "@/main/constants/MutationType";
import {ActionType} from "@/main/constants/ActionType";
import _ from "lodash";
import Utils from "@/common/service/Utils";
import SiteApiService from "@/main/service/SiteApiService";
import {ListResponseModel} from "../../../common/type/apiRequest";
import {BrandEntity} from "../../types/entity";

const module: Module<BrandsState, RootState> = {
    state: new BrandsState,
    mutations: {
        [MutationType.BRANDS_SET_TOP] (state, topBrands) {
            state.topBrands = topBrands;
        },
        [MutationType.BRANDS_LIST_UPDATE] (state, data: ListResponseModel<BrandEntity>) {
            state.isLoading = false;
            state.isMore = data.pageSize === data.items.length;
            state.page++;
            _.forEach(data.items, (item) => {
                Utils.mergeArrayById(state.list, item, 'id')
            });
        },
        [MutationType.BRANDS_LIST_SET_LOADING] (state, isLoading) {
            state.isLoading = isLoading;
        },
        [MutationType.BRANDS_LIST_RESET] (state) {
            state.page = 1;
            state.list = [];
        },
    },
    getters: {
        getTopBrands: state => {
            return state.topBrands
        },
        getBrandsList: state => {
            return state.list
        }
    },
    actions: {
        [ActionType.BRANDS_LIST_LOAD]: _.throttle(async ({ commit, state, getters }) => {
            commit(MutationType.BRANDS_LIST_SET_LOADING, true);
            const response = await SiteApiService.brandsGetList(state.page);
            if (response.isSuccess) {
                commit(MutationType.BRANDS_LIST_UPDATE, response.data);
            } else {
                commit(MutationType.BRANDS_LIST_SET_LOADING, false);
            }

            commit(
                MutationType.BROWSER_META_SET,
                {
                    seoTitle: 'Все бренды - ElTehnika.com',
                    seoDescription: 'Все бренды - ElTehnika.com',
                    seoKeywords: ''
                }
            );
        }, 500),
    }
};

export default module;
