import { RootState, UserState } from "@/main/types/store";
import { Module } from "vuex";
import { MutationType } from "@/main/constants/MutationType";
import { ActionType } from "@/main/constants/ActionType";
import SiteApiService from "@/main/service/SiteApiService";
import _ from "lodash";

const module: Module<UserState, RootState> = {
    state: new UserState,
    mutations: {
        [MutationType.USER_UPDATE](state, data) {
            state.data = data.data;
            state.isLoggedIn = data.isLoggedIn;
        },
        [MutationType.USER_SHOW_LOGIN_MODAL](state) {
            state.isShowLoginModal = true;
        },
        [MutationType.USER_HIDE_LOGIN_MODAL](state) {
            state.isShowLoginModal = false;
        },
        [MutationType.USER_SHOW_REGISTRATION_MODAL](state) {
            state.isShowRegistrationModal = true;
        },
        [MutationType.USER_HIDE_REGISTRATION_MODAL](state) {
            state.isShowRegistrationModal = false;
        },
        [MutationType.USER_SET_IS_LOGGED_IN](state) {
            state.isLoggedIn = true;
        },
        [MutationType.USER_SET_IS_LOGOUT](state) {
            state.isLoggedIn = false;
            state.data = null;
        }
    },
    getters: {
        isUserLoggedIn: state => {
            return state.isLoggedIn
        },
        isAdmin: state => {
            return _.get(state.data, 'role') === 'Admin';
        }
    },
    actions: {
        async [ActionType.USER_LOGOUT]({commit}) {
            commit(MutationType.USER_SET_IS_LOGOUT);
            await SiteApiService.userLogout();
        },
    }
};

export default module;
