export enum ProductSortType {
    PRICE_DESC = 'PriceDesc',
    PRICE_ASC = 'PriceAsc',
    POPULAR = 'Popular',
    NEWEST = 'Newest',
}

export namespace ProductSortType {
    export function toString(type: ProductSortType) {
        switch (type) {
            case ProductSortType.POPULAR:
                return 'По популярности';
            case ProductSortType.PRICE_DESC:
                return 'От дорогих к дешевым';
            case ProductSortType.PRICE_ASC:
                return 'От дешевых к дорогим';
            case ProductSortType.NEWEST:
                return 'Новинки';
        }
    }

    export function getSelectList() {
        return [
            {name: ProductSortType.toString(ProductSortType.POPULAR), value: ProductSortType.POPULAR},
            {name: ProductSortType.toString(ProductSortType.NEWEST), value: ProductSortType.NEWEST},
            {name: ProductSortType.toString(ProductSortType.PRICE_DESC), value: ProductSortType.PRICE_DESC},
            {name: ProductSortType.toString(ProductSortType.PRICE_ASC), value: ProductSortType.PRICE_ASC},
        ]
    }
}
