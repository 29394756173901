import {BasketState, RootState} from "@/main/types/store";
import {Module} from "vuex";
import {MutationType} from "@/main/constants/MutationType";
import {ActionType} from "@/main/constants/ActionType";
import _ from "lodash";
import SiteApiService from "@/main/service/SiteApiService";

const module: Module<BasketState, RootState> = {
    state: new BasketState,
    mutations: {
        [MutationType.BASKET_UPDATE] (state, data) {
            state.amount = data.amount;
            state.productsQuantity = data.productsQuantity;
            state.basketItems = data.basketItems;
            state.isLoading = false;
        },
        [MutationType.BASKET_IS_LOADING] (state, isLoading) {
            state.isLoading = isLoading;
        },
        [MutationType.BASKET_CLOSE_MODAL] (state) {
            state.isShowBasketModal = false;
        },
        [MutationType.BASKET_SHOW_MODAL] (state) {
            state.isShowBasketModal = true;
        },
        [MutationType.BASKET_RESET] (state) {
            state.amount = 0;
            state.productsQuantity = 0;
            state.basketItems = [];
        }
    },
    getters: {
        isProductInBasket: state => (productId: number) =>  {
            return !!(_.find(state.basketItems, { productId: productId }));
        },
        isBasketEmpty: state => {
            return state.basketItems.length === 0;
        },
    },
    actions: {
        async [ActionType.BASKET_LOAD_AND_UPDATE] ({ commit, state }) {
            commit(MutationType.BASKET_IS_LOADING, true);
            const response = await SiteApiService.basketGet();
            commit(MutationType.BASKET_UPDATE, response.data);
        },
        async [ActionType.BASKET_ADD_REMOVE_PRODUCT] ({ commit, state }, data) {
            commit(MutationType.BASKET_IS_LOADING, true);
            commit(
                MutationType.PRODUCTS_LIST_UPDATE_PRODUCT,
                { id: data.productId, isExistInBasket: !!(data.quantity) }
            );
            const response = await SiteApiService.basketSetProduct(data.productId, data.quantity);
            commit(MutationType.BASKET_UPDATE, response.data);
        }
    }
};

export default module;
