

import { Component, Prop } from "vue-property-decorator";
import Vue, { CreateElement, VNode } from "vue";

@Component({
    components: {}
})
export default class Nl2br extends Vue {
    @Prop({required: true, type: String})
    readonly tag!: string;

    @Prop({required: true, type: String})
    readonly text!: string;

    @Prop({required: false, type: String})
    readonly className!: string;

    public render(createElement: CreateElement): VNode {
        let parentElement = createElement(this.tag, {
            'class': this.className
        })
        let textParts = this.text.split('\n');
        parentElement.children = textParts.map(stringElement => {
            let child = createElement('p');
            child.text = stringElement;
            return child;
        });
        return parentElement;
    }
}
