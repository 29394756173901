import { BrowserMetaState, RootState, UserState } from "@/main/types/store";
import { Module } from "vuex";
import { MutationType } from "@/main/constants/MutationType";
import { ActionType } from "@/main/constants/ActionType";
import SiteApiService from "@/main/service/SiteApiService";
import _ from "lodash";

const module: Module<BrowserMetaState, RootState> = {
    state: new BrowserMetaState,
    mutations: {
        [MutationType.BROWSER_META_SET](state, data) {
            state.seoTitle = data.seoTitle ?? '';
            state.seoDescription = data.seoDescription ?? '';
            state.seoKeywords = data.seoKeywords ?? '';

            document.title = state.seoTitle;
            document.querySelector('meta[name="description"]')?.setAttribute("content", state.seoDescription);
            document.querySelector('meta[name="keywords"]')?.setAttribute("content", state.seoKeywords);
        },
    },
    getters: {

    },
    actions: {

    }
};

export default module;
