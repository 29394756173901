

import {Component, Watch} from "vue-property-decorator";
import {BreadcrumbsType} from "@/main/constants/BreadcrumbsType";
import {BrandEntity, CategoryEntity} from "@/main/types/entity";
import * as _ from 'lodash';
import {Getter, State} from "vuex-class";
import Utils from "@/common/service/Utils";
import {Route} from "vue-router";
import MySiteVue from "@/main/components/MySiteVue";

interface BreadcrumbModel {
    name: string;
    url: string;
}

@Component({
    components: {}
})
export default class Breadcrumbs extends MySiteVue {

    @State(state => state.productsList.viewData.brand)
    readonly productsListBrand!: BrandEntity;

    private currentType: BreadcrumbsType | null = null;

    created() {
        this.setType(this.$route);
    }

    getCategoryUrl(category: CategoryEntity) {
        return category.children.length > 0
            ? '/category/' + category.url
            : '/category/products/' + category.url
    }

    getCategoryBreadcrumbs() {
        const parentCategoryId = _.get(this.$route, 'params.shortName');
        const category = this.getCategoryById(parentCategoryId);
        if (!category) {
            return;
        }
        return _.map(category.parentIdsPath, parentCategoryId => {
            const parentCategory =  this.getCategoryById(parentCategoryId);
            return {
                name: parentCategory.name,
                url: this.getCategoryUrl(parentCategory)
            };
        });
    }

    getProductsBreadcrumbs() {
        const breadcrumbs: BreadcrumbModel[] = [];
        const product = this.$store.getters.getCurrentProduct;
        if (!product) {
            return breadcrumbs;
        }
        const productBreadcrumb = {
            name: product.title,
            url: product.url
        };
        const category =  this.getCategoryById(product.categoryId);
        if (!category) {
            breadcrumbs.push(productBreadcrumb);
            return breadcrumbs;
        }
        _.forEach(category.parentIdsPath, parentCategoryId => {
            const parentCategory = this.$store.getters.getCategoryById(parentCategoryId);
            breadcrumbs.push({
                name: parentCategory.name,
                url: this.getCategoryUrl(parentCategory)
            });
        });
        breadcrumbs.push(productBreadcrumb);
        return breadcrumbs;
    }

    getBrandsBreadcrumbs() {
        return [{
            name: 'Все бренды',
            url: '/brands'
        }];
    }

    getBrandProductsBreadcrumbs() {
        const breadcrumbs = this.getBrandsBreadcrumbs();
        if (this.productsListBrand) {
            breadcrumbs.push({
                name: this.productsListBrand.name,
                url: this.productsListBrand.url
            });
        }
        return breadcrumbs;
    }

    getSearchBreadcrumbs() {
        const searchString = _.get(this.$route, 'params.searchString');
        return [{
            name: `Результаты поиска: "${decodeURIComponent(searchString)}"`,
            url: '/search/' + encodeURIComponent(searchString)
        }];
    }

    setType(path: Route) {
        this.currentType = BreadcrumbsType.getFromRoutePath(path.path);
    }

    get
    breadcrumbs() {
        if (this.currentType === BreadcrumbsType.SEARCH_PAGE) {
            return this.getSearchBreadcrumbs();
        }
        if (this.currentType === BreadcrumbsType.ORDER_SENT_PAGE) {
            return [{
                name: 'Спасибо за покупку',
                url: '/'
            }];
        }
        if (this.currentType === BreadcrumbsType.USER_ORDERS_PAGE) {
            return [{
                name: 'Мои заказы',
                url: '/user/orders'
            }];
        }
        if (this.currentType === BreadcrumbsType.USER_WISHLIST_PAGE) {
            return [{
                name: 'Список желаний',
                url: '/user/wishList'
            }];
        }
        if (this.currentType === BreadcrumbsType.CONTACTS_PAGE) {
            return [{
                name: 'Наши контакты',
                url: '/contacts'
            }];
        }
        if (this.currentType === BreadcrumbsType.CATEGORY_PAGE) {
            return this.getCategoryBreadcrumbs();
        }
        if (this.currentType === BreadcrumbsType.PRODUCT_PAGE) {
            return this.getProductsBreadcrumbs();
        }
        if (this.currentType === BreadcrumbsType.BRANDS_PAGE) {
            return this.getBrandsBreadcrumbs();
        }
        if (this.currentType === BreadcrumbsType.BRAND_PRODUCTS_PAGE) {
            return this.getBrandProductsBreadcrumbs();
        }
        if (this.currentType === BreadcrumbsType.MAIN) {
            return [{
                name: 'Главная',
                url: '/'
            }];
        }
        let title = _.get(this.$route, 'meta.title');
        if (!title) {
            title = document.title;
        }
        if (title) {
            return [{
                name: title,
                url: this.$route.path
            }]
        }
        return '';
    }

    get
    isHide() {
        return this.currentType === BreadcrumbsType.MAIN
    }

    @Watch('$route')
    routePathWatcher(route: Route) {
        this.setType(route);
    }
}
