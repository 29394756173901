
import {Component, Prop} from "vue-property-decorator";
import MySiteVue from "../../components/MySiteVue";
import {ProductEntity} from "../../types/entity";

@Component({
    components: {}
})
export default class ProductFeaturesBlock extends MySiteVue {
    @Prop({required: true, type: Object})
    readonly product!: ProductEntity;
}
