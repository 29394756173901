

import { Component, Ref } from "vue-property-decorator";
import InfoBlock from "@/common/element/InfoBlock.vue";
import StringInputField from "@/common/element/form/StringInputField.vue";
import PhoneNumberInputField from "@/common/element/form/PhoneNumberInputField.vue";
import SelectDeliveryService from "@/main/components/form/SelectDeliveryService.vue";
import { Getter, Mutation } from "vuex-class";
import { MutationType } from "@/main/constants/MutationType";
import { IValidatedField } from "@/common/element/form/IValidatedField";
import { OrderFormFormState } from "@/main/types/store";
import MySiteVue from "@/main/components/MySiteVue";
import { RegExConstants } from "@/common/constants/RegExConstants";

@Component({
    components: {
        SelectDeliveryService,
        PhoneNumberInputField,
        StringInputField,
        InfoBlock
    }
})
export default class UserInfoBlock extends MySiteVue {

    @Mutation(MutationType.ORDER_UPDATE)
    readonly orderUpdate!: (data: object) => null;

    @Getter('getOrderFormData')
    readonly formData!: OrderFormFormState;

    @Ref()
    readonly firstNameField!: IValidatedField;

    @Ref()
    readonly lastNameField!: IValidatedField;

    @Ref()
    readonly phoneNumberField!: IValidatedField;

    @Ref()
    readonly emailField!: IValidatedField;

    private emailRegExp = RegExConstants.email;

    onContinue() {
        let isValidFirstName = this.firstNameField.validate();
        let isValidLastName = this.lastNameField.validate();
        let isValidPhoneNumber = this.phoneNumberField.validate();
        let isValidEmail = this.emailField ? this.emailField.validate() : true;
        if (!isValidFirstName || !isValidLastName || !isValidPhoneNumber || !isValidEmail) {
            return;
        }
        this.$emit('onContinue');
    }

    onChangeField(name: string, value: any) {
        this.orderUpdate({
            [name]: value
        })
    }
}
