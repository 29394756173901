
import {Component} from "vue-property-decorator";
import MySiteVue from "../components/MySiteVue";
import {Getter} from "vuex-class";
import OrderForm from "@/main/components/order/OrderForm.vue";
import { MutationType } from "@/main/constants/MutationType";

@Component({
    components: {
        OrderForm
    }
})
export default class OrderPage extends MySiteVue {

    @Getter('isAppInitialized')
    readonly isAppInitialized!: boolean;

    @Getter('isBasketEmpty')
    readonly isBasketEmpty!: boolean;

    mounted() {
        this.$store.commit(
            MutationType.BROWSER_META_SET,
            {
                seoTitle: 'Мои заказы',
                seoDescription: 'Мои заказы',
                seoKeywords: ''
            }
        );
    }

    created() {
        if (this.isBasketEmpty && this.isAppInitialized) {
            this.$router.replace('/error404');
        }
    }
}
