

import {Component, Watch} from "vue-property-decorator";
import PageTitle from "@/main/components/common/PageTitle.vue";
import * as _ from 'lodash';
import {Action, Mutation} from "vuex-class";
import MySiteVue from "@/main/components/MySiteVue";
import SiteApiService from "../service/SiteApiService";
import {MutationType} from "../constants/MutationType";
import {ActionType} from "../constants/ActionType";
import ErrorBlock from "@/common/element/ErrorBlock.vue";

@Component({
    components: {
        PageTitle,
        ErrorBlock
    },
    created() {
        console.log(1111)
    }
})
export default class EmailVerificationPage extends MySiteVue {

    @Mutation(MutationType.USER_SET_IS_LOGGED_IN)
    readonly setUserLoggedIn!: (isLogged: boolean) => void;

    @Action(ActionType.APP_INIT)
    readonly appInit!: () => void;

    private verificationToken: string | null = null;
    private errorMessage: string = '';

    created() {
        console.log(22222)
        this.setTokenId();
        this.checkToken();
    }

    async checkToken() {
        this.errorMessage = '';
        if (!this.verificationToken)
        {
            return;
        }
        const response = await SiteApiService.userRegistrationStep2(this.verificationToken);
        if (response.isSuccess) {
            this.setUserLoggedIn(true);
            this.appInit();
            await this.$router.push('/')
        } else {
            this.errorMessage = response.message;
        }
        this.verificationToken = null;
    }

    setTokenId() {
        this.verificationToken = _.get(this.$route, 'params.token');
    }

    get
    pageTitle(): string {
        return 'Авторизация на сайте'
    }

    @Watch('$route')
    onRouteChanged() {
        this.setTokenId();
        this.checkToken();
    }
}
