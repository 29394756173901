

import {Component} from "vue-property-decorator";
import PageTitle from "../components/common/PageTitle.vue";
import MySiteVue from "../components/MySiteVue";
import {ProductEntity} from "@/main/types/entity";
import SiteApiService from "@/main/service/SiteApiService";
import Utils from "@/common/service/Utils";
import Spinner from "@/common/element/Spinner.vue";
import { MutationType } from "@/main/constants/MutationType";

@Component({
    components: {
        PageTitle,
        Spinner
    }
})
export default class UserWishListPage extends MySiteVue {

    private products: ProductEntity[] = [];
    private isLoading: boolean = false;

    mounted() {
        if (!this.isLoggedIn) {
            this.$router.replace('/');
        }
        this.$store.commit(
            MutationType.BROWSER_META_SET,
            {
                seoTitle: 'Мой список желаний',
                seoDescription: 'Мой список желаний',
                seoKeywords: ''
            }
        );
    }

    async created() {
        this.isLoading = true;
        const response = await SiteApiService.productsWishListGet();
        if (response.isSuccess) {
            this.products = response.data.items;
        }
        this.isLoading = false;
    }

    async onRemove(productId: number) {
        Utils.deleteFromArrayById(this.products, productId);
        await SiteApiService.productsWishListSet(false, productId);
    }
}
