

import {Component, Prop, Watch} from "vue-property-decorator";
import MySiteVue from "@/main/components/MySiteVue";
import {ProductEntity} from "../../types/entity";
import AddCommentModal from "../../components/modal/AddCommentModal.vue";
import CommentItem from "@/main/page/product/CommentItem.vue";
import {Getter, Mutation} from "vuex-class";

@Component({
    components: {
        AddCommentModal,
        CommentItem
    }
})
export default class ProductCommentsBlock extends MySiteVue {
    @Prop({required: false, type: Object})
    readonly product!: ProductEntity;

    private isShowAddModal: boolean = false;
    private parentCommentId: number | null = null;
    private tryToShowAddModalAfterLogin: boolean = false;

    beforeDestroy() {
        this.tryToShowAddModalAfterLogin = false;
    }

    showAddModal(parentCommentId: number | null) {
        this.parentCommentId = parentCommentId;

        if (!this.isLoggedIn) {
            this.tryToShowAddModalAfterLogin = true;
            this.showLoginModal();
            return;
        }

        this.isShowAddModal = true;
    }

    get
    reviewCount() {
        return this.product.comments
            && this.product.comments.length > 0 ? this.product.comments.length : 1;
    }

    @Watch('isLoggedIn')
    isUserLoggedInWatcher(isUserLoggedIn: boolean) {
        if (isUserLoggedIn && this.tryToShowAddModalAfterLogin) {
            this.isShowAddModal = true;
            this.tryToShowAddModalAfterLogin = false;
        }
    }
}
